import React from 'react';
import './AboutCompanyContent.scss';
import Section from '../Section';
import Container from '../Container';
import MainTitle from '../MainTitle';
import {RentabelnoArtLogo} from '../../images';
import Description from '../Description';
import Advantages from '../Advantages';
import {ADVANTAGE_POINTS} from '../Constants';
import GoToMainSiteLink from '../GoToMainSiteLink';
import RandomFlats from '../RandomFlats';
import SecondaryTitle from '../SecondaryTitle';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';

const AboutCompanyContent = ({...props}) => {
  return (
      <Section white className="about-company">
        <Container className="about-company__container">
          <div className="about-company__content">
            <MainTitle title="О компании"
                       className="about-company__title"/>

            <div className="about-company__logo">
              <RentabelnoArtLogo/>
            </div>

            <Description className="about-company__description"
                         description="Проект «Рентабельно» - это уникальный сервис по быстрой и удобной
                         аренде жилья, паркингов и вещей.Мы отличаемся тем что мы не посредники, а работаем
                         исключительно с объектами, которые
                          являются нашей собственностью - сдаем свои квартиры, паркинги и вещи.Таким образом,
                           мы находим индивидуальный
                          подход к каждому к клиенту,
                          и предоставляем сервис, полностью отвечающий их нуждам."
                         center/>
            <Description className="about-company__description"
                         description="Мы предлагаем не только высококачественный сервис, но и очень
                         приятные цены по аренде различных объектов.Так как у нас отсутствует понятие
                         комиссии, цены за аренду полностью соответствуют предоставляемому
                          качеству, без начисления дополнительных процентов за услуги риэлторов."
                         center/>


            <Advantages className="about-company__advantages"
                        horizontal
                        points={ADVANTAGE_POINTS}/>

            <div className="about-company__link-to-main">
              <GoToMainSiteLink />
            </div>

            <SecondaryTitle center
                            className="about-company__subtitle"
                            title="Мы предлагаем отличные квартиры в аренду"/>

            <RandomFlats className="about-company__random-flats"/>

            <div className="about-company__all-flats">
              <CustomLink url={ FLATS_ROUTE }
                          button
                          title="Все объекты"/>
            </div>

          </div>
        </Container>
      </Section>
  );
};

export default AboutCompanyContent;
