import React from 'react';

import { ReactComponent as Logo } from './logo-main.svg';
import { ReactComponent as LogoW } from './logo-white.svg';
import { ReactComponent as LogoM } from './mini-logo.svg';
import { ReactComponent as Marker } from './product-marker.svg';

import { ReactComponent as NoCommission } from './advantages/no-commission.svg';
import { ReactComponent as Rentabelno } from './advantages/rentabelno.svg';
import { ReactComponent as Shield } from './advantages/shield.svg';
import { ReactComponent as RentabelnoArt } from './rentabelno-art.svg';

import { ReactComponent as Home } from './home.svg';

export const HomeIcon = (props) => {
  return <Home {...props} />;
};

export const RentabelnoArtLogo = (props) => {
  return <RentabelnoArt {...props}/>
}

export const LogoMain = (props) => {
  return <Logo {...props}/>
}

export const LogoWhite = (props) => {
  return <LogoW {...props}/>
}

export const LogoMini = (props) => {
  return <LogoM {...props}/>
}

export const NoCommissionIcon = (props) => {
  return <NoCommission {...props}/>
}

export const RentabelnoIcon = (props) => {
  return <Rentabelno {...props}/>
}

export const ShieldIcon = (props) => {
  return <Shield {...props}/>
}


export const MarkerIcon = (props) => {
  return <Marker {...props}/>
}
