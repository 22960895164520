import React from 'react';
import './SliderItem.scss';

const SliderItem = (props) => {
  return (
    <div
      {...props}
      className={`slider-item`}
    />
  );
};

export default SliderItem;
