import React from 'react';
import './SectionComplex.scss';
import Section from '../Section';
import Container from '../Container';
import SecondaryTitle from '../SecondaryTitle';
import Description from '../Description';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';
import ComplexImage from '../ComplexImage';
import ComplexAdvantages from '../ComplexAdvantages';
import ComplexFeatures from '../ComplexFeatures';

const SectionComplex = ({sectionTitle = 'Преимущества комплекса', ...props}) => {
  return (
      <Section {...props}
               className="section-complex">
        <Container className="section-complex__container">
          <div className="section-complex__content">
            <SecondaryTitle className="section-complex__title"
                            center
                            title={sectionTitle}/>

            <div className="section-complex__inner">
              <div className="section-complex__left">
                <ComplexImage/>
                <ComplexAdvantages/>
              </div>
              <div className="section-complex__right">
                <Description className="section-complex__description"
                             description="Жилой комплекс «Акварели» площадью свыше 180 тысяч квадратных метров,
                               представляет собой единый монолит с собственной инфраструктурой и системой подземного
                               паркинга. «Акварели» - это индивидуальный строительный проект с панорамным остеклением
                                квартир и вентилируемым оригинальным фасадом,
                               срок службы которого рассчитан как минимум на 150 лет."/>
                <Description className="section-complex__description"
                             description="Благодаря использованию современных экологичных
                               материалов в квартирах ЖК «Акварели» в любое время года поддерживается
                                оптимальный микроклимат. «Акварели» - это непревзойденное сочетание изящной
                                современной архитектуры, развитой внутренней инфраструктуры (коммерческие
                                 помещения, школа, детский сад) и транспортной доступности."/>
                <CustomLink url={ FLATS_ROUTE }
                            className="section-complex__search"
                            button
                            title="Найти квартиру"/>
              </div>
            </div>

            <SecondaryTitle className="section-complex__title"
                            center
                            title="Особенности"/>


            <ComplexFeatures className="section-complex__features"/>

          </div>
        </Container>
      </Section>
  );
};

export default SectionComplex;
