import React from 'react';
import './AboutComplexPosition.scss';
import Section from '../Section';
import Container from '../Container';
import SecondaryTitle from '../SecondaryTitle';
import PositionList from '../PositionIList';
import GoogleMaps from '../GoogleMaps/GoogleMaps';

const  AboutComplexPosition = ({...props}) => {
  return (
      <Section white className="about-complex-position">
        <Container className="about-complex-position__container">
          <div className="about-complex-position__content">
            <SecondaryTitle className="about-complex-position__subtitle"
                            title="Расположение"
                            center/>


            <PositionList className="about-complex-position__position-list"/>

            <GoogleMaps className="about-complex-position__map"/>
          </div>
        </Container>
      </Section>
  );
};

export default AboutComplexPosition;
