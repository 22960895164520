export const FLATS_ROUTE = '/flats';
export const ABOUT_COMPLEX_ROUTE = '/about-complex';
export const ABOUT_COMPANY_ROUTE = '/about-company';
export const CONTACTS_ROUTE = '/contacts';
export const QUESTIONS_ROUTE = '/questions';
export const REVIEWS_ROUTE = '/reviews';
export const POLICY_ROUTE = '/company-policy';
export const TERM_ROUTE = '/term-of-use';

export const MAIN_SITE_ROUTE = 'https://www.rentabelno.com/'
export const MAIN_SITE_ZHILIE_ROUTE = 'https://www.rentabelno.com/zhilie'

