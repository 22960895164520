import React from 'react';
import Select from '../Select';
import {valueFromId} from '../Utils';

const options = [
  {label: 'Студия' , value: -1},
  {label: '1 Комната', value: 1},
  {label: '2 Комнаты', value: 2},
  {label: '3 и более' , value: 3},
]

const RoomsCountSelectField = ({currentValueId, ...props}) => {
  return (
      <Select {...props}
              isClearable
              value={valueFromId(options, Number(currentValueId)) ?? ''}
              placeholder="Количество комнат"
              options={options}/>
  );
};

export default RoomsCountSelectField;
