import React from 'react';
import './ToManyRequestError.scss';

const ToManyRequestError = ({error, ...props}) => {
  return (
      <>
        { !!error &&
        <div className="to-many-request-error">
          { error }
        </div>
        }
      </>
  );
};

export default ToManyRequestError;
