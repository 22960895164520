import React from 'react';
import './InfoAboutMainCompany.scss';
import {LogoMain} from '../../images';
import Description from '../Description';
import GoToMainSiteLink from '../GoToMainSiteLink';

const InfoAboutMainCompany = ({className = '', ...props}) => {
  return (
      <div className={ `${ className } info-about-main-company` }>
        <div className="info-about-main-company__inner">
          <div className="info-about-main-company__left">
            <LogoMain/>
          </div>
          <div className="info-about-main-company__right">
            <Description className="info-about-main-company__description"
                         description="Проект «Рентабельно» - это уникальный сервис по быстрой
                          и удобной аренде жилья, паркингов и вещей. Мы отличаемся тем что мы не
                           посредники, а работаем исключительно с объектами, которые являются нашей
                           собственностью - сдаем свои квартиры, паркинги и вещи. Таким образом, мы
                            находим индивидуальный подход к каждому клиенту,
                         и предоставляем сервис, полностью отвечающий их нуждам."/>
            <Description className="info-about-main-company__description"
                         description="Мы предлагаем не только высококачественный сервис, но и очень
                         приятные цены по аренде различных объектов. Так как у нас отсутствует
                          понятие комиссии, цены за аренду полностью соответствуют предоставляемому качеству,
                         без начисления дополнительных процентов за услуги риэлторов."/>
            <GoToMainSiteLink className="info-about-main-company__link"/>
          </div>
        </div>
      </div>
  );
};

export default InfoAboutMainCompany;
