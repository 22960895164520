import React from 'react';
import './ComplexAdvantages.scss';
import {GardenIcon, KinderGardenIcon, MarketsIcon, ParkingIcon, SportIcon, SunIcon} from '../../icons';
import {uuidv4} from '../Utils';
import ComplexAdvantagesItem from '../ComplexAdvantagesItem';

const icons = [
  {type: 'kids', icon: <KinderGardenIcon/>},
  {type: 'garden', icon: <GardenIcon/>},
  {type: 'sun', icon: <SunIcon/>},
  {type: 'parking', icon: <ParkingIcon/>},
  {type: 'markets', icon: <MarketsIcon/>},
  {type: 'sport', icon: <SportIcon/>},
];

const ComplexAdvantages = ({className = '', ...props}) => {
  return (
      <div className={ `${ className } complex-advantages` }>
        <ul className="complex-advantages-list">
          { icons.map(item => (
              <li key={ uuidv4() }
                  className="complex-advantages-list__item">
                <ComplexAdvantagesItem item={ item }/>
              </li>
          )) }

        </ul>
      </div>

  );
};

export default ComplexAdvantages;
