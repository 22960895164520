import React from 'react';
import Select from '../Select';
import {valueFromId} from '../Utils';
import {components} from 'react-select';

const options = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
  {label: '13', value: 13},
  {label: '14', value: 14},
];

const ValueContainer = ({children, ...props}) => {
  const {hasValue} = props;

  if ( !hasValue ) {
    return (
        <components.ValueContainer { ...props }>
          { children }
        </components.ValueContainer>
    );
  }
  return (
      <components.ValueContainer { ...props }>
        Этаж:&nbsp; { children }

      </components.ValueContainer>
  );
};


const FloorSelectField = ({currentValueId, ...props}) => {
  return (
      <Select { ...props }
              isClearable
              components={ {ValueContainer} }
              value={ valueFromId(options, Number(currentValueId)) ?? '' }
              placeholder="Этаж"
              options={ options }/>
  );
};

export default FloorSelectField;
