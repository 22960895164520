import React, {Fragment} from 'react';
import './AboutComplexContent.scss';
import SectionComplex from '../SectionComplex';
import AboutComplexFlats from '../AboutComplexFlats';
import AboutComplexPosition from '../AboutComplexPosition';

const AboutComplexContent = ({...props}) => {
  return (
      <Fragment>
        <SectionComplex white
                        sectionTitle="О комплексе"/>
        <AboutComplexFlats/>
        <AboutComplexPosition/>
      </Fragment>
  );
};

export default AboutComplexContent;
