import React from 'react';
import CustomLink from '../CustomLink';
import {RentabelnoLogoIcon} from '../../icons';
import {MAIN_SITE_ROUTE} from '../RouterConstasts';

const HeaderLogo = ({className = '', ...props}) => {
  return (
      <div className={ className }>
        <CustomLink className="header__logo-link"
                    url="/">
          Аренда, продажа квартир <br/>
          и паркингов в ЖК Акварели<br/>
          (г. Балашиха)
        </CustomLink>
        <div className="header__logo-wrap">
          <span className="header__logo-divider">от компании</span>
          <a href={MAIN_SITE_ROUTE}
             rel="noreferrer"
             className="header__logo-icon"
             target="_blank">
          <RentabelnoLogoIcon/>
          </a>
        </div>
      </div>
  );
};

export default HeaderLogo;
