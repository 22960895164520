import React from 'react';
import './LoadingIndicator.scss';

const LoadingIndicator = ({...props}) => {
  return (
      <div className="loading-wrap">
        <div className="loading">
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>

  );
};

export default LoadingIndicator;
