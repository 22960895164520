import React, {useEffect} from 'react';
import MainContent from '../../components/MainContent';
import {setPageTitle} from '../../components/Utils';

const MainPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Снять квартиру в ЖК Акварели в Балашихе');
  });
  return (
      <MainContent/>
  );
};

export default MainPage;
