import axios from 'axios';
import queryString from 'query-string';

export const getFlats = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`realty?subtype=Flat&type=Apartment&group=${process.env.REACT_APP_AKVARELI_ID}&${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const getRandomFlats = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`realty/offers?subtype=Flat&type=Apartment&group=${process.env.REACT_APP_AKVARELI_ID}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const sendCallback = (data) => {
  return new Promise((resolve, reject) => {
    axios.post('back_call', data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const sendContacts = (data) => {
  return new Promise((resolve, reject) => {
    axios.post('contact', data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


