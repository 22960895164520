import {combineReducers} from 'redux';
import modalReducer from './modalReducer';

const appReducer = combineReducers({
  modal: modalReducer
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
