import React from 'react';
import './MainContent.scss';
import SectionOffer from '../SectionOffer';
import SectionFlats from '../SectionFlats';
import SectionComplex from '../SectionComplex';
import SectionPosition from '../SectionPosition';

const MainContent = ({...props}) => {
  return (
      <>
        <SectionOffer/>
        <SectionFlats/>
        <SectionComplex silver/>
        <SectionPosition/>
      </>
  );
};

export default MainContent;
