import React from 'react';
import {components} from 'react-select';
import './ComfortSelectField.scss';
import makeAnimated from 'react-select/animated';
import Select from '../Select';

const animatedComponents = makeAnimated();

const options = [
  {label: 'Можно с животными', value: 1},
  {label: 'Балкон', value: 2},
  {label: 'Можно с детьми', value: 3},
  {label: 'Дом. кинотеатр', value: 4},
  {label: 'Кондиционер', value: 5},
  {label: 'Уютный интерьер', value: 6},
  {label: 'Холодильник', value: 7},
  {label: 'Жилая мебель', value: 8},
  {label: 'Интернет', value: 9},
  {label: 'Кухонная мебель', value: 10},
  {label: 'Рядом с метро', value: 11},
  {label: 'Стиралка', value: 12},
  {label: 'Парковочное место', value: 13},

  //from parkings
  // {label: 'Близко к метро', value: 14},
  // {label: 'Охрана', value: 15},
  // {label: 'Рядом ТЦ', value: 16},
  // {label: 'Есть блокиратор', value: 17},
];


const ValueContainer = ({children, ...props}) => {
  const {getValue, hasValue} = props;
  const nbValues = getValue().length;
  if ( !hasValue ) {
    return (
        <components.ValueContainer { ...props }>
          { children }
        </components.ValueContainer>
    );
  }
  return (
      <components.ValueContainer { ...props }>
        Удобства:&nbsp;{ nbValues }&nbsp;шт.
        {children}
      </components.ValueContainer>
  );
};

const ComfortsSelectField = ({currentValueIds, ...props}) => {

  let currentValues = () => {
    let value = [];

    //get values array ids from search params
    //search from options and comparison with search params
    //check if it is array and continue

    if ( currentValueIds.length > 0 && Array.isArray(JSON.parse(currentValueIds)) ) {

      JSON.parse(currentValueIds)
          .forEach(item => {
            value.push(options.find(x => x.value === Number(item)));
          });

      return value;
    }


    return '';
  };


  return (
      <Select { ...props }
              isMulti
              hideSelectedOptions={ false }
              components={ {ValueContainer, animatedComponents} }
              value={ currentValues() ?? '' }
              placeholder="Удобства"
              options={ options }/>
  );
};

export default ComfortsSelectField;
