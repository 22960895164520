import React from 'react';
import './Checkbox.scss';

const Checkbox = ({className = '', checked, label = false, id, onChange, center, name, value, children, error, ...props}) => {
  return (
      <div className={ `${ className } checkbox 
                      ${ error ? 'checkbox--error' : '' }` }>
        <div className="checkbox__wrapper">
          <input id={ id }
                 className="checkbox__field"
                 name={ name }
                 value={ value }
                 checked={ checked }
                 onChange={ onChange }
                 type="checkbox"/>
          { label &&
          <label htmlFor={ id }
                 className={ `checkbox__label 
                              ${ center ? 'checkbox__label--center' : '' }
                              ` }>
            { children }
          </label>
          }

        </div>

        { error &&
        <div className="checkbox__error">{ error }</div>
        }
      </div>
  );
};

export default Checkbox;
