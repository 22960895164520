import React from 'react';
import './SecondaryTitle.scss';

const SecondaryTitle = ({className = '', title, orange = false, center = false, ...props}) => {
  return (
      <h2 className={`secondary-title 
                      ${orange ? 'secondary-title--orange' : ''}
                      ${center ? 'secondary-title--center' : ''}
                      ${className}`}>
        { title }
      </h2>
  );
};

export default SecondaryTitle;
