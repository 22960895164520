import React, {memo} from 'react';
import {uuidv4} from '../Utils';


const GoogleMaps = ({className = '', center, markerPosition, ...props}) => {

  let params = {
    center: '55.79827797038466, 37.95984901270234',
    q: 'ЖК «Акварели»+пр. Ленина, 32а, Балашиха, Московская обл., Росія, 143907',
    zoom: '18',
    key: process.env.REACT_APP_MAP_KEY,
  };

  return (
      <div className={`${className} google-map`}>
        <iframe width="100%"
                height="500"
                title={ uuidv4() }
                style={ {border: 0} }
                loading="lazy"
                allowFullScreen
                src={ `https://www.google.com/maps/embed/v1/place?key=${ params.key }
              &q=${ params.q }&center=${ params.center }&zoom=${ params.zoom }` }/>
      </div>

  );
};

export default memo(GoogleMaps);
