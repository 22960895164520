import React from 'react';
import './SuccesRequestDescription.scss';
import {ApplyIcon} from '../../icons';

const SuccessRequestDescription = ({description, ...props}) => {

  return (
      <div className="success">
        <div className="success__inner">
          <div className="success__icon">
            <ApplyIcon/>
          </div>
          <div className="success__text">
            { description }
          </div>
        </div>
      </div>
  );
};

export default SuccessRequestDescription;
