import React, {useEffect} from 'react';
import AboutCompanyContent from '../../components/AboutCompanyContent';
import {setPageTitle} from '../../components/Utils';

const AboutCompanyPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('О компании');
  });
  return (
      <AboutCompanyContent/>
  );
};

export default AboutCompanyPage;
