import React, {useEffect} from 'react';
import ContactsContent from '../../components/ContactsContent';
import {setPageTitle} from '../../components/Utils';

const ContactsPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Контакты');
  });
  return (
      <ContactsContent/>
  );
};

export default ContactsPage;
