import React from 'react';
import './PositionList.scss';
import {uuidv4} from '../Utils';
import {BusIcon, CarIcon, MetroIcon, TrainIcon} from '../../icons';
import PositionItem from '../PositionItem';

const breakpoints = [
  {text: ['Всего 7 км', <br key={uuidv4()}/>, 'до МКАД на авто'], icon: <CarIcon/>},
  {text: ['40 минут до', <br key={uuidv4()}/> , 'Курского вокзала'], icon: <TrainIcon/>},
  {text: ['100 меров до', <br key={uuidv4()}/>, 'общественного транспорта'], icon: <BusIcon/>},
  {text: ['20 мин до',<br key={uuidv4()}/> ,'метро на транспорте'], icon: <MetroIcon/>},
]

const PositionList = ({className = '', ...props}) => {
  return (
      <ul className={`${className} position-list`}>
        {breakpoints.map(point => (
            <li key={uuidv4()}
                className="position-list__item">
              <PositionItem point={point}/>
            </li>
        ))}
      </ul>
  );
};

export default PositionList;
