import React from 'react';
import './InputLabel.scss';

const InputLabel = ({label, ...props}) => {
  return (
      <label
          {...props}
          className="input-label"
      >
        { label }
      </label>
  );
};

export default InputLabel;
