import React from 'react';
import './FlatsContent.scss';
import Section from '../Section';
import Container from '../Container';
import SecondaryTitle from '../SecondaryTitle';
import PositionList from '../PositionIList';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import AllFlats from '../AllFlats';
import PageHeader from '../PageHeader';
import {HomeIcon} from '../../images';

const FlatsContent = ({...props}) => {
  return (
      <Section white
               className="flats">
        <Container className="flats__container">
          <div className="flats__content">

            <PageHeader className="flats__header"
                        title="Квартиры"
                        filter
                        available
                        filterButton
                        icon={() => <HomeIcon/>}/>

            <AllFlats className="flats__all"/>


            <SecondaryTitle className="flats__subtitle"
                            title="Расположение"
                            center/>

            <PositionList className="flats__position-list"/>

            <GoogleMaps className="flats__map"/>
          </div>
        </Container>
      </Section>
  );
};

export default FlatsContent;
