import React, {memo, useCallback, useEffect, useState} from 'react';
import Objects from './index';
import LoadingIndicator from '../LoadingIndicator';
import MessageWhenIsNoData from '../MessageWhenIsNoData';
import {ERROR_LOADING_DATA} from '../Constants';
import {getRandomFlats} from '../../actions/flatsActions';

const ObjectsContainer = ({className = '', activeTab, ...props}) => {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let getAkvareliFlats = useCallback(() => {
    setLoading(true);
    getRandomFlats()
        .then(response => {
          setObjects(response);
          setLoading(false);
        })
        .catch(err => {
          setError(true);
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    getAkvareliFlats();
  },[getAkvareliFlats]);


  return (
      <>
        { loading && <LoadingIndicator/> }
        { (!loading && !!objects?.length) && <Objects objects={ objects }
                                                     className="objects"/> }

        { error &&
          <MessageWhenIsNoData text={ ERROR_LOADING_DATA }
                               className="error-message"/>
        }
      </>
  );
};

export default memo(ObjectsContainer);
