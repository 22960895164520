import React from 'react';
import './HeaderLinks.scss';
import {ABOUT_COMPANY_ROUTE, ABOUT_COMPLEX_ROUTE, CONTACTS_ROUTE, FLATS_ROUTE} from '../RouterConstasts';
import CustomLink from '../CustomLink';
import {uuidv4} from '../Utils';

const links = [
  {title: 'Квартиры', url: FLATS_ROUTE},
  {title: 'О комплексе', url: ABOUT_COMPLEX_ROUTE},
  {title: 'О компании', url: ABOUT_COMPANY_ROUTE},
  {title: 'Контакты', url: CONTACTS_ROUTE}
]

const HeaderLinks = ({className = '', ...props}) => {
  return (
      <div className={`${className} links`}>
        <ul className="links-list">
          {links.map(link => (
              <li key={uuidv4()}
                  className="links-list__item">
                <CustomLink title={link.title}
                            url={link.url}/>
              </li>
          ))}

        </ul>
      </div>
  );
};

export default HeaderLinks;
