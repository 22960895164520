import React from 'react';
import {useHistory} from 'react-router-dom';
import './ClearFiltersBtton.scss';
import Button from '../Button';

const ClearFiltersButton = ({className = '', ...props}) => {
  let history = useHistory();

  let clearParams = () => {
    history.push({
      pathname: window.location.pathname,
    });
  };

  return (
      <Button className={ `clear-filter-button ${ className }` }
              filterClear
              onClick={ clearParams }>
        Очистить фильтр
      </Button>
  );
};

export default ClearFiltersButton;
