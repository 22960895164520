import React from 'react';
import './Copyright.scss';
import FooterLogo from '../FooterLogo';

const Copyright = ({className = '', ...props}) => {
  return (
      <div className={`copyright ${className}`}>
        <FooterLogo className="copyright__icon"/>
        <div className="copyright__text">
          &copy; {new Date().getFullYear()} «Рентабельно»
        </div>
      </div>

  )
};

export  default Copyright
