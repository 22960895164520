import React, {useRef, useState} from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ButtonDropdown from '../ButtonDropdown';

const ButtonWithDropdown = ({classNameButton = '',
                              classNameDropdown = '', dropdownComponent: Dropdown = null, ...props}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();

  let showMenuHandler = () => {
    setOpen(!isOpen);
  };

  useOnClickOutside(ref, showMenuHandler);

  return (
      <ButtonDropdown className={classNameButton}
                      {...props}
                      active={isOpen}
                      onClick={showMenuHandler}>
        {isOpen &&
          <div className={classNameDropdown}
               ref={ref}>
            <Dropdown onClick={showMenuHandler}/>
          </div>
        }
      </ButtonDropdown>
  );
};

export default ButtonWithDropdown;
