import React from 'react';
import './FlatsFeatures.scss';
import {ComfortIcon, InteriorIcon, PlansIcon, TechIcon} from '../../icons';
import {uuidv4} from '../Utils';
import FlatFeature from '../FlatFeature';

const features = [
  {title: 'Удобные планировки', icon: <PlansIcon/>},
  {title: 'Красивый интерьер', icon:  <InteriorIcon/>},
  {title: 'Комфортная мебель', icon: <ComfortIcon/>},
  {title: 'Современная бытовая техника', icon: <TechIcon/>},
];

const FlatsFeatures = ({className = '', ...props}) => {
  return (
      <div className={ `${ className } flats-features` }>
        <ul className="flats-features-list">
          { features.map(feature => (
                <li key={uuidv4()}
                    className="flats-features-list__item">
                  <FlatFeature feature={feature}/>
                </li>
              )
          ) }
        </ul>
      </div>
  );
};

export default FlatsFeatures;
