import React from 'react';
import './Button.scss';

const Button = ({
                  className = '',
                  activeClassName = 'button--active',
                  filter = false,
                  filterClear = false,
                  submit = false,
                  title = '',
                  icon: Icon = null,
                  style = {},
                  fullWidth = false,
                  children,
                  disabled = false,
                  active, ...props
                }) => {
  return (
      <React.Fragment>
        <button className={ `button 
                ${ active ? `${ activeClassName }` : '' }
                ${ Icon ? 'button--has-icon' : '' }
                ${ submit ? 'button--submit' : '' }
                ${ fullWidth ? 'button--full-width' : '' }
                ${ disabled ? 'button--disabled' : '' }
                ${ filter ? 'button--filter' : '' }
                ${ filterClear ? 'button--filter-clear' : '' }
               ${ className }
                ` }
                style={ {...style} }
                { ...props }>
          { Icon && (
              <div className="button__icon">
                <Icon/>
              </div>
          ) }
          <div className="button__text">
            { title }
          </div>

          { children }
        </button>
      </React.Fragment>
  );
};

export default Button;
