import React from 'react';
import './Slider.scss';
import ReactSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import {ArrowLeftIcon, ArrowRightIcon} from '../../icons';


const PrevArrow = ({className, onClick}) => {
  return (
      <ArrowLeftIcon onClick={ onClick }
                     className={ className }/>
  );
};

const NextArrow = ({className, onClick}) => {
  return (
      <ArrowRightIcon onClick={ onClick }
                      className={ className }/>
  );
};

const Slider = ({children}) => {

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return (
      <ReactSlider { ...settings }>
        { children }
      </ReactSlider>
  );
};

export default Slider;
