import React from 'react';
import './ContactsContent.scss';
import Section from '../Section';
import Container from '../Container';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import MainTitle from '../MainTitle';
import OurContacts from '../OurContacts';
import ContactForm from '../ContactForm';

const ContactsContent = ({...props}) => {
  return (
      <Section className="contacts" white>
        <Container className="contacts__container">
          <div className="contacts__content">
            <MainTitle title="Контакты"
                       className="contacts__title"/>

            <OurContacts className="contacts__our-contacts"/>

            <div className="contacts__inner">
              <ContactForm className="contacts__form"/>

              <GoogleMaps className="contacts__map"/>
            </div>
          </div>
        </Container>
      </Section>
  );
};

export default ContactsContent;
