import React from 'react';
import './ComplexAdvantagesItem.scss';

const ComplexAdvantagesItem = ({item, ...props}) => {
  return (
      <div className={`complex-advantages-item complex-advantages-item--${item.type}`}>
        { item.icon }
      </div>
  );
};

export default ComplexAdvantagesItem;
