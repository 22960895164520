import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import './FilterStatus.scss';
import {ApplyIcon} from '../../icons';

const FilterStatus = ({...props}) => {
  const [hasQuery, setHasQuery] = useState(false);
  const location = useLocation();
  const query = queryString.parse(location.search);

  useEffect(() => {
    if(Object.keys(query).length > 0 && !!Object.keys(query).indexOf('page')) {
      setHasQuery(true)
    } else {
      setHasQuery(false)
    }
  }, [query]);

  return (
      <>
        {hasQuery
            ? <div className="filter-status">
                <ApplyIcon/>
              </div>
            : null

        }

      </>
  );
};

export default FilterStatus;
