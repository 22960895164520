import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {getFlats} from '../../actions/flatsActions';
import LoadingIndicator from '../LoadingIndicator';
import Objects from '../Objects';
import MessageWhenIsNoData from '../MessageWhenIsNoData';
import {NOT_FOUND_DATA} from '../Constants';
import Pagination from '../Pagination';

const AllFlats = ({className = '', ...props}) => {
  const location = useLocation();
  const history = useHistory();
  const [flats, setFlats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);

  let handlePageChange = (page) => {
    const search = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  const getAllFlats = useCallback(() => {
    const query = queryString.parse(location.search);

    setLoading(true)
    getFlats(query)
        .then(response => {
          setFlats(response.data)
          setMeta(response.meta)
          setLoading(false)
        })
        .catch(errors => {
          setLoading(false)
        })
  }, [location.search]);

  useEffect(() => {
    getAllFlats();
  }, [getAllFlats]);

  return (
      <div className={ className }>
        { loading && <LoadingIndicator/> }
        { (!loading && !!flats?.length) && <Objects objects={ flats }
                                                   className="flats"/> }

        { !flats?.length &&
          <MessageWhenIsNoData text={ NOT_FOUND_DATA }
                               className="error-message"/>
        }

        {meta?.last_page > 1 &&
             <Pagination current={ meta.current_page }
                          total={ meta.total }
                          perPage={ meta.per_page }
                          onChange={ handlePageChange }/>
        }

      </div>
  );
};

export default AllFlats;
