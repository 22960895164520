import React from 'react';
import './CustomLink.scss';
import {Link, useLocation} from 'react-router-dom';

const CustomLink = ({className = '', title, url, button, children,  ...props}) => {
  const location = useLocation();

  return (
      <Link to={ url }
            className={`${className} link 
                       ${location.pathname === url ? 'link--disabled' : ''}
                        ${button ? 'link--like-button': ''}`}
            {...props}>
        { title ? title : children}
      </Link>
  );
};

export default CustomLink;
