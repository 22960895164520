import React from 'react';
import FilteredCheckbox from '../FilteredCheckbox';

const FreeFilterChekboxField = ({...props}) => {
  return (
      <FilteredCheckbox label="Показать только свободные"
                        name="free"
                        className="free-checkbox"/>
  );
};

export default FreeFilterChekboxField;
