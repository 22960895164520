import React from 'react';
import './ReviewItem.scss';

const ReviewItem = ({review, ...props}) => {
  return (
      <div className="review">
        <div className="review__header">
          <div className="review__avatar">
            <img src={ review.avatar } alt=""/>
          </div>
          <div className="review__client-info">
            <div className="review__client-name">
              { review.name }
            </div>
            <div className="review__created">
              { review.created_at }
            </div>
            <div className="review__location">
              { review.location }
            </div>
          </div>
        </div>
        <div className="review__body">
          <div className="review__dignity">
            <div className="review__dignity-title">
              Достоинства
            </div>
            <div className="review__dignity-message">
              { review.dignity }
            </div>
          </div>
          <div className="review__limitations">
            <div className="review__limitations-title">
              Недостатки
            </div>
            <div className="review__limitations-message">
              { review.limitations }
            </div>
          </div>
        </div>
      </div>
  );
};

export default ReviewItem;
