import React from 'react';
import './FlatFeature.scss';

const FlatFeature = ({feature, ...props}) => {
  return (
      <div className="flat-feature">
        <div className="flat-feature__icon">
          { feature.icon }
        </div>
        <div className="flat-feature__text">
          { feature.title }
        </div>
      </div>
  );
};

export default FlatFeature;
