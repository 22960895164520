import React from 'react';
import './ThirdTitle.scss';

const ThirdTitle = ({className = '', title, ...props}) => {
  return (
      <h3 className={`${className} third-title`}>
        { title }
      </h3>
  );
};

export default ThirdTitle;
