import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Content from './Content';
import ScrollToTop from './Router/ScrollToTop';
import YandexMetrika from './YandexMetrika';
import ScrollToTopButton from './ScrollToTopButton';
import Header from './Header';
import Footer from './Footer';
import MainPage from '../pages/MainPage/MainPage';
import {
  ABOUT_COMPANY_ROUTE,
  ABOUT_COMPLEX_ROUTE,
  CONTACTS_ROUTE,
  FLATS_ROUTE,
  POLICY_ROUTE,
  REVIEWS_ROUTE,
  TERM_ROUTE
} from './RouterConstasts';
import CallBackButton from './CallBackButton';
import CallbackModal from './CallbackModal';
import ContactsPage from '../pages/ContactsPage/ContactsPage';
import TermPage from '../pages/TermPage/TermPage';
import AboutCompanyPage from '../pages/AboutCompanyPage/AboutCompanyPage';
import AboutComplexPage from '../pages/AboutComplexPage/AboutComplexPage';
import ReviewsPage from '../pages/ReviewsPage/ReviewsPage';
import PolicyPage from '../pages/PolicyPage/PolicyPage';
import FlatsPage from '../pages/FlatsPage/FlatsPage';
import SuccessModal from './SuccessModal';

const App = ({...props}) => {
  return (
      <Fragment>
        <Router>
          <ScrollToTop/>
          <YandexMetrika/>
          <div className="wrapper">
            <Header/>

            <Content>
              <Switch>
                <Route exact path={ '/' } component={ MainPage }/>
                <Route exact path={ FLATS_ROUTE } component={ FlatsPage }/>
                <Route exact path={ ABOUT_COMPLEX_ROUTE } component={ AboutComplexPage }/>
                <Route exact path={ ABOUT_COMPANY_ROUTE } component={ AboutCompanyPage }/>
                <Route exact path={ CONTACTS_ROUTE } component={ ContactsPage }/>
                {/*<Route exact path={ QUESTIONS_ROUTE } component={ QuestionsPage }/>*/}
                <Route exact path={ REVIEWS_ROUTE } component={ ReviewsPage }/>
                <Route exact path={ POLICY_ROUTE } component={ PolicyPage }/>
                <Route exact path={ TERM_ROUTE } component={ TermPage }/>
              </Switch>
            </Content>


            <Footer/>
          </div>

          <CallbackModal/>
          <SuccessModal/>
          <CallBackButton/>
          <ScrollToTopButton/>
        </Router>
      </Fragment>
  );
};

export default App;
