import React from 'react';
import './FromToFilterField.scss';
import Input from '../Input';

const FromToFilterField = ({className = '', label, valueFrom, valueTo, nameFrom, nameTo, handleChange, ...props}) => {

  return (
      <div className={ `${ className } from-to-fields` }>
        <div className="from-to-fields__label">
          { label }
        </div>
        <div className="from-to-fields__fields-wrap">
          <Input name={ nameFrom }
                 id={ nameFrom }
                 size="es"
                 className="from-to-fields__field"
              // error={errors.getError('email')}
                 value={ valueFrom }
                 onKeyDown={ handleChange }
                 onChange={ handleChange }
                 placeholder={ `от` }/>

          <div className="from-to-fields__divider">-</div>

          <Input name={ nameTo }
                 id={ nameTo }
                 size="es"
                 className="from-to-fields__field"
              // error={errors.getError('email')}
                 value={ valueTo }
                 onKeyDown={ handleChange }
                 onChange={ handleChange }
                 placeholder={ `до` }/>
        </div>
      </div>
  );
};

export default FromToFilterField;
