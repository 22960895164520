import React from 'react';
import Modal from '../Modal';
import {useDispatch, useSelector} from 'react-redux';
import {closeSuccessModal} from '../../actions/modalActions';
import SuccessRequestDescription from '../SuccesRequestDescription';

const SuccessModal = ({...props}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.success.isOpen);
  const description = useSelector(state => state.modal.success.payload);

  let handleClose = () => {
    dispatch(closeSuccessModal());
  };

  return (
      <Modal isOpen={ isOpen }
             onRequestClose={ handleClose }>
        <SuccessRequestDescription description={ description }/>
      </Modal>
  );
};

export default SuccessModal;
