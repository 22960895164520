import React, {useState} from 'react';
import './PageHeader.scss';
import {FilterCloseIcon, FilterIcon} from '../../icons';
import FreeFilterCheckboxField from '../FreeFilterCheckboxField';
import Button from '../Button';
import FilterStatus from '../FilterStatus';
import FlatsFilter from '../FlatsFilter';

const PageHeader = ({className = '', title, icon: Icon = null,
                      available = false, features, filterButton = false, filter = false,  ...props}) => {
  const [showFilter, setShowFilter] = useState();

  let handleShowFilter = () => {
    setShowFilter(!showFilter)
  }

  return (
      <div className={`${className} page-header`}>
        <div className="page-header__inner">
          <div className="page-header__top">
            <div className="page-header__top-left">
              <div className="page-header__icon">
                <Icon/>
              </div>
              <h1 className="page-header__title">
                {title}
              </h1>
            </div>
            <div className="page-header__top-right">
              {available && <FreeFilterCheckboxField/>}
              {(filterButton && !showFilter) &&
                  <Button title="Показать фильтр"
                          className="page-header__filter-btn"
                          onClick={handleShowFilter}
                          icon={() => <FilterIcon/>}
                          filter>
                    <FilterStatus/>
                  </Button>
              }

              {(filterButton && showFilter) &&
                  <Button title="Скрыть фильтр"
                          className="page-header__filter-btn"
                          onClick={handleShowFilter}
                          icon={() => <FilterCloseIcon/>}
                          filter>
                    <FilterStatus/>
                  </Button>
              }
            </div>
          </div>
          {(filter && showFilter) &&
            <div className="page-header__filter-wrap">
              <FlatsFilter className="page__header-filter"/>
            </div>
          }
          <div className="page-header__bottom">
            { features }
          </div>
        </div>
      </div>
  );
};

export default PageHeader;
