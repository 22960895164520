import React, {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import './ContactForm.scss';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormControl from '../FromControl';
import InputLabel from '../InputLabel';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import Checkbox from '../Checkbox';
import CustomLink from '../CustomLink';
import {POLICY_ROUTE, TERM_ROUTE} from '../RouterConstasts';
import Button from '../Button';
import {sendContacts} from '../../actions/flatsActions';
import {useDispatch} from 'react-redux';
import {openSuccessModal} from '../../actions/modalActions';
import {RECAPTCHA_CONTACT_ACTION, SUCCESS_CALLBACK_MESSAGE} from '../Constants';
import ToManyRequestError from '../ToManyRequestError';

const initialState = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  comment: '',
  term: false,
  policy: false,
  errors: new Errors()
};


const ContactForm = ({className = '', ...props}) => {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();


  let handleChange = (e) => {
    const {value, name, type, checked} = e.target;

    setState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };



  let handleSubmit = async () => {
    setLoading(true);

    try {
      const token = await executeRecaptcha(RECAPTCHA_CONTACT_ACTION);

      const data = {
        name: state.name,
        surname: state.surname,
        email: state.email,
        phone: state.phone,
        comment: state.comment,
        term: state.term,
        policy: state.policy,
        token
      };

      sendContacts(data)
          .then(() => {
            setLoading(false);
            setState(initialState);
            dispatch(openSuccessModal(SUCCESS_CALLBACK_MESSAGE));
          })
          .catch(errors => {
            setLoading(false);
            setState(prevState => ({
              ...prevState,
              errors: new Errors(errors)
            }));
          });
    } catch {
      setLoading(false);
    }

  };



  return (
      <div className={ `${ className } contact-form` }>
        <FormContainer center
                       className="contact-form__container">

          {/*<SecondaryTitle className="contact-form__title"*/ }
          {/*                title="Обратная связь"*/ }
          {/*                center/>*/ }

          <FormRow>
            <FormControl size="md">
              <InputLabel htmlFor="name"
                          label="Имя"/>
              <Input name="name"
                     id="name"
                     fullWidth
                     error={ state.errors.getError('name') }
                     placeholder="Ваше имя"
                     type="name"
                     value={ state.name }
                     onChange={ handleChange }/>
            </FormControl>

            <FormControl size="md">
              <InputLabel htmlFor="surname"
                          label="Фамилия"/>
              <Input name="surname"
                     id="surname"
                     fullWidth
                     error={ state.errors.getError('surname') }
                     placeholder="Ваша фамилия"
                     type="surname"
                     value={ state.surname }
                     onChange={ handleChange }/>
            </FormControl>
          </FormRow>

          <FormRow>
            <FormControl size="md">
              <InputLabel htmlFor="phone"
                          label="Телефон"/>
              <PhoneInput onChange={ handleChange }
                          fullWidth
                          error={ state.errors?.getError('phone') }
                          placeholder={ `Ваш телефон` }
                          name="phone"
                          id="phone"
                          value={ state.phone }/>
            </FormControl>

            <FormControl size="md">
              <InputLabel htmlFor="email"
                          label="Email"/>
              <Input name="email"
                     id="email"
                     fullWidth
                     error={ state.errors.getError('email') }
                     placeholder="Ваш email"
                     type="email"
                     value={ state.email }
                     onChange={ handleChange }/>
            </FormControl>
          </FormRow>

          <FormRow>
            <FormControl>
              <InputLabel htmlFor="comment"
                          label="Комментарий"/>
              <Input name="comment"
                     id="comment"
                     fullWidth
                     rows={ 5 }
                     error={ state.errors.getError('comment') }
                     placeholder="Ваш текст..."
                     multiline
                     type="text"
                     value={ state.comment }
                     onChange={ handleChange }/>
            </FormControl>
          </FormRow>

          <FormRow>
            <FormControl>
              <Checkbox onChange={ handleChange }
                        error={ state.errors.getError('term') }
                        checked={ state.term }
                        value={ state.term }
                        name="term"
                        id="term_secondary"
                        label>
                Я даю своё согласие на&nbsp;
                <CustomLink url={ TERM_ROUTE }
                            target="_blank"
                            className="contact-form__link"
                            title="обработку персональных данных"/>
              </Checkbox>
            </FormControl>
          </FormRow>
          <FormRow>
            <FormControl>
              <Checkbox onChange={ handleChange }
                        error={ state.errors.getError('policy') }
                        checked={ state.policy }
                        value={ state.policy }
                        name="policy"
                        id="policy_secondary"
                        label>
                Я согласен с&nbsp;
                <CustomLink url={ POLICY_ROUTE }
                            target="_blank"
                            className="contact-form__link"
                            title="политикой компании"/>
              </Checkbox>
            </FormControl>
          </FormRow>
          <FormRow>
            <FormControl>
              <Button submit
                      disabled={ loading }
                      onClick={ handleSubmit }
                      fullWidth
                      title="Отправить"/>

              <ToManyRequestError error={state.errors.getError('0') || state.errors.getError('token')}/>

            </FormControl>
          </FormRow>
        </FormContainer>
      </div>
  );
};

export default ContactForm;
