import React from 'react';
import Select from '../Select';
import {valueFromName} from '../Utils';

const sort_types = [
  {label: 'От дорогих к дешевым', value: 'desc'},
  {label: 'От дешевых к дорогим', value: 'asc'},
];


const SortTypeSelectField = ({currentValue, ...props}) => {
  return (
      <Select {...props}
              isClearable
              value={valueFromName(sort_types, currentValue) ?? ''}
              placeholder="Сортировка"
              options={sort_types}/>
  );
};

export default SortTypeSelectField;
