import React from 'react';
import './HotCall.scss';
import {PhoneIcon} from '../../icons';
import Phone from '../Phone';
import {useDispatch} from 'react-redux';
import {openCallbackModal} from '../../actions/modalActions';

const HotCall = ({className = '', ...props}) => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openCallbackModal())
  }

  return (
      <div className={`${className} hot-call`}>
        <div className="hot-call__icon">
          <PhoneIcon/>
        </div>
        <div className="hot-call__inner">
          <Phone className="hot-call__phone"
                 formattedPhone="+7 (495) 221-52-59"
                 phone="74952215259"/>
         <div onClick={handleOpenModal}
              className="hot-call__callback">
           Перезвонить мне
         </div>
        </div>
      </div>
  );
};

export default HotCall;
