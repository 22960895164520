import React from 'react';
import './Contacts.scss';
import Phone from '../Phone';
import {PhoneIcon, PinIcon} from '../../icons';

const Contacts = ({className = '', ...props}) => {
  return (
    <div className={`contacts ${className}`}>
      <div className="contacts__phone">
        <PhoneIcon className="contacts__phone-icon"/>
        <Phone phone="74952215259"
               formattedPhone="+7 (495) 221-52-59"/>
      </div>
      <div className="contacts__city">
        <PinIcon className="contacts__city-icon"/>
        г. Балашиха проспект Ленина, 32А
      </div>
    </div>
  )
};

export default Contacts;
