import * as actionTypes from './actionTypes';

export const openModal = ({modalType = '', payload = null}) => ({
  type: actionTypes.OPEN_MODAL,
  modalType: modalType,
  payload: payload
});

export const closeModal = ({modalType = ''}) => ({
  type: actionTypes.CLOSE_MODAL,
  modalType: modalType,
});


export const openCallbackModal = () => openModal({
  modalType: 'callback',
});

export const closeCallbackModalModal = () => closeModal({
  modalType: 'callback',
});

export const openSuccessModal = (description) => openModal({
  modalType: 'success',
  payload: description
});

export const closeSuccessModal = () => closeModal({
  modalType: 'success',
});


