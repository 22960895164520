import React from 'react';
import './MessageWhenIsNoData.scss';


const MessageWhenIsNoData = ({className = '', text, ...props}) => {
  return (
      <div className={ `message-when-no-data ${ className }` }>
        <h2 className="message-when-no-data">
          { text }
        </h2>
      </div>
  );
};

export default MessageWhenIsNoData;
