import React from 'react';
import './ReviewsContent.scss';
import Section from '../Section';
import Container from '../Container';
import RandomFlats from '../RandomFlats';
import SecondaryTitle from '../SecondaryTitle';
import MainTitle from '../MainTitle';
import Description from '../Description';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';
import ReviewsSlider from '../ReviewsSlider';

const ReviewsContent = ({...props}) => {
  return (
    <Section white
              className="section-reviews">
      <Container className="section-reviews__container">
        <div className="section-reviews__content">
          <MainTitle title="Отзывы"
                     className="section-reviews__title"/>

          <Description className="section-reviews__description"
                       description="Проект «Рентабельно» - это локальный сервис по быстрой и удобной аренде жилья,
                       вещей и паркингов. Все объекты на сайте являются нашей собственностью - мы сдаем свои
                        квартиры, паркинги и вещи."/>
          <Description className="section-reviews__description section-reviews__description--secondary"
                       description="Мы будем признательны Вам за любые замечания и отзывы о нашей работе,
                        а также за предложения по улучшению качества обслуживания. На их основе мы
                         сможем исправлять существующие недочеты и более полно удовлетворять Ваши потребности."/>

          <ReviewsSlider className="section-reviews__slider-reviews"/>

          <SecondaryTitle title="Наши квартиры"
                          center
                          className="section-reviews__subtitle"/>
          <RandomFlats className="section-reviews__flats"/>


          <div className="section-reviews__all-flats">
            <CustomLink url={ FLATS_ROUTE }
                        button
                        title="Все объекты"/>
          </div>

        </div>
      </Container>
    </Section>
  );
};

export default ReviewsContent;
