import React from 'react';
import './PositionItem.scss';

const PositionItem = ({point, ...props}) => {
  return (
      <div className="position">
        <div className="position__icon">
          {point.icon}
        </div>
        <div className="position__text">
          {point.text}
        </div>
      </div>
  );
};

export default PositionItem;
