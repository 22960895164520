import React, {useEffect} from 'react';
import {setPageTitle} from '../../components/Utils';
import FlatsContent from '../../components/FlatsContent/FlatsContent';

const FkatsPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Квартиры');
  });
  return (
      <FlatsContent/>
  );
};

export default FkatsPage;
