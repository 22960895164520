import React from 'react';
import './IsSellFilterCheckboxField.scss';
import FilteredCheckbox from '../FilteredCheckbox';

const IsSellFilterCheckboxField = ({...props}) => {
  return (
      <FilteredCheckbox label="Возможна продажа"
                        name="is_sell"
                        className="sell-checkbox"/>
  );
};

export default IsSellFilterCheckboxField;
