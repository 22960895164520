import React, {PureComponent} from 'react';
import './Pagination.scss';
import {ArrowLeftIcon, ArrowRightIcon, ChevronsLeftIcon, ChevronsRightIcon} from '../../icons';

class Pagination extends PureComponent {
  render = () => {
    const { current, total, perPage, onChange } = this.props;

    if ((total / perPage) <= 1) {
      return null;
    }

    const firstPage = 1;
    const lastPage = Math.ceil(total / perPage);

    const previousPage = (current - 1) > 0 ? current - 1 : null;
    const nextPage = current < lastPage ? parseInt(current) + 1 : null;

    return (
        <div className="pagination">

          <div className={`pagination__item ${firstPage === current  ? 'pagination__item--disable' : ''}`}
               onClick={() => onChange(firstPage)}>
            <ChevronsLeftIcon/>
          </div>

          <div className={`pagination__item ${!previousPage ? 'pagination__item--disable' : ''}`}
               onClick={() => !previousPage ? null : onChange(previousPage)}>
            <ArrowLeftIcon/>
          </div>

          {(parseInt(current)) > 5 && (
              <React.Fragment>
                <div className="pagination__item"
                    onClick={() => onChange(firstPage)}>
                  {firstPage}
                </div>

                <div className="pagination__divider">
                  ...
                </div>
              </React.Fragment>
          )}

          {parseInt(current) > 5 && [parseInt(current) - 2, parseInt(current) - 1, parseInt(current), parseInt(current) + 1, parseInt(current) + 2].map(page => {
            if (page > 0 && page <= lastPage) {
              return (
                  <React.Fragment key={page}>
                    {page === parseInt(current) ? (
                        <div className="pagination__item pagination__item--selected">
                          {page}
                        </div>
                    ) : (
                        <div
                            className="pagination__item"
                            onClick={() => onChange(page)}
                        >
                          {page}
                        </div>
                    )}
                  </React.Fragment>
              );
            }

            return null;
          })}

          {parseInt(current) <= 5 && [parseInt(current) - 4, parseInt(current) - 3, parseInt(current) - 2, parseInt(current) - 1, parseInt(current), parseInt(current) + 1, parseInt(current) + 2, parseInt(current) + 3, parseInt(current) + 4, parseInt(current) + 5, parseInt(current) + 6].map(page => {
            if (page > 0 && page <= lastPage) {
              return (
                  <React.Fragment key={page}>
                    {page === parseInt(current) ? (
                        <div className="pagination__item pagination__item--selected">
                          {page}
                        </div>
                    ) : (
                        <div className="pagination__item"
                            onClick={() => onChange(page)}>
                          {page}
                        </div>
                    )}
                  </React.Fragment>
              );
            }

            return null;
          })}

          {(parseInt(current) + 6) < lastPage && (
              <React.Fragment>
                <div className="pagination__divider">
                  ...
                </div>
                <div className="pagination__item"
                    onClick={() => onChange(lastPage)}>
                  {lastPage}
                </div>
              </React.Fragment>
          )}

          <div className={`pagination__item ${!nextPage ? 'pagination__item--disable' : ''}`}
              onClick={() => !nextPage ? null : onChange(nextPage)}>
            <ArrowRightIcon/>
          </div>
          <div className={`pagination__item ${lastPage === current  ? 'pagination__item--disable' : ''}`}
               onClick={() => onChange(lastPage)}>
            <ChevronsRightIcon/>
          </div>
        </div>
    );
  };
}

export default Pagination;
