import React from 'react';
import AboutComplexContent from '../../components/AboutComplexContent';

const AboutComplexPage = ({...props}) => {

  return (
      <AboutComplexContent/>
  );
};

export default AboutComplexPage;
