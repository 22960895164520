import React from 'react';
import './TermsContent.scss';
import Section from '../Section';
import Container from '../Container';
import MainTitle from '../MainTitle';

const TermsContent = ({...props}) => {
  return (
      <Section white
               className="terms">
        <Container className="terms__container">
          <div className="terms__container">
            <MainTitle title="Пользовательское соглашение"
                       className="terms__title"/>

            <div className="terms__subtitle">
              Соглашение на обработку персональных данных сайтом akvareli.org
            </div>

            <ol className="terms-list">
              <li className="terms-list__item">
                1. Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте akvareli.org, (далее – Сайт),
                путем заполнения полей онлайн-заявки (регистрации) Пользователь:
              </li>
              <li className="terms-list__item">
                <ol className="terms-sublist-list">
                  <li className="terms-sublist-list__item">
                    Подтверждает, что указанные им персональные данные принадлежат лично ему;
                  </li>
                  <li className="terms-sublist-list__item">
                    Признает и подтверждает, что он внимательно и в полном объеме ознакомился с настоящим
                    Соглашением и
                    содержащимися в нем условиями обработки его персональных данных, указываемых им в полях он-лайн
                    заявки
                    (регистрации) на сайте;
                  </li>
                  <li className="terms-sublist-list__item">
                    Признает и подтверждает, что все положения настоящего Соглашения и условия обработки его
                    персональных данных
                    ему понятны;
                  </li>
                  <li className="terms-sublist-list__item">
                    Дает согласие на обработку Сайтом предоставляемых персональных данных в целях регистрации
                    Пользователя на
                    Сайте;
                  </li>
                  <li className="terms-sublist-list__item">
                    Выражает согласие с условиями обработки персональных данных.
                  </li>
                </ol>
              </li>
            </ol>

            <ol className="terms-list">
              <li className="terms-list__item">
                2. Пользователь дает свое согласие на обработку его персональных данных, а именно совершение действий,
                предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», и
                подтверждает, что, давая такое согласие, он действует свободно, своей волей и в своем интересе.
              </li>
              <li className="terms-list__item">
                3. Согласие Пользователя на обработку персональных данных является конкретным, информированным и
                сознательным.
              </li>
            </ol>

            <ol className="terms-list">
              <li className="terms-list__item">
                4. Настоящее согласие Пользователя применяется в отношении обработки следующих персональных данных:
              </li>
              <li className="terms-list__item">
                <ol className="terms-sublist-list">
                  <li className="terms-sublist-list__item">
                    Фамилия, Имя, Отчество;
                  </li>
                  <li className="terms-sublist-list__item">
                    Номер телефона;
                  </li>
                  <li className="terms-sublist-list__item">
                    Электронную почту (E-mail);
                  </li>
                  <li className="terms-sublist-list__item">
                    Данные о юридическом лице
                  </li>
                </ol>
              </li>
            </ol>

            <ol className="terms-list">
              <li className="terms-list__item ">
                5. Пользователь, предоставляет сервису akvareli.org право осуществлять следующие действия (операции) с
                персональными данными:
              </li>
              <li className="terms-list__item">
                <ol className="terms-sublist-list">
                  <li className="terms-sublist-list__item">
                    Сбор и накопление;
                  </li>
                  <li className="terms-sublist-list__item">
                    Хранение в течение установленных нормативными документами сроков хранения отчетности, но не
                    менее трех лет,
                    с момента даты прекращения пользования услуг Сайта Пользователем;
                  </li>
                  <li className="terms-sublist-list__item">
                    Уточнение (обновление, изменение);
                  </li>
                  <li className="terms-sublist-list__item">
                    Использование в целях регистрации Пользователя на Сайте;
                  </li>
                  <li className="terms-sublist-list__item">
                    Уничтожение;
                  </li>
                  <li className="terms-sublist-list__item">
                    Передача по требованию суда, либо уполномоченных лиц в т.ч. третьим лицам, с соблюдением мер,
                    обеспечивающих
                    защиту персональных данных от несанкционированного доступа.
                  </li>
                </ol>
              </li>
            </ol>

            <ol className="terms-list">
              <li className="terms-list__item ">
                6. Заключительные положения
              </li>
              <ol className="terms-sublist">
                <li className="terms-sublist__item">
                  6.1. Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано Вами
                  путем
                  подачи заявления администрации Сайта с указанием данных, определенных ст. 14 Закона «О персональных
                  данных».
                </li>
                <li className="terms-sublist__item">
                  6.2. Отзыв согласия на обработку персональных данных может быть осуществлен путем направления
                  Пользователем
                  соответствующего распоряжения в простой письменной форме на адрес электронной почты (E-mail)
                  info@akvareli.org.
                </li>
                <li className="terms-sublist__item">
                  6.3. Сайт не несет ответственности за использование (как правомерное, так и неправомерное) третьими
                  лицами
                  информации, размещенной Пользователем на Сайте, включая её воспроизведение и распространение,
                  осуществленные
                  всеми возможными способами.
                </li>
                <li className="terms-sublist__item">
                  6.4. Сайт имеет право вносить изменения в настоящее Соглашение. При внесении изменений в актуальной
                  редакции
                  указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее
                  размещения,
                  если иное не предусмотрено новой редакцией Соглашения.
                </li>
                <li className="terms-sublist__item">
                  6.4 Действующая редакция всегда находится на странице по адресу: akvareli.org. К настоящему Соглашению
                  и
                  отношениям между пользователем и Сайтом, возникающим в связи с применением Соглашения подлежит
                  применению
                  материальное и процессуальное право Российской Федерации.
                </li>
              </ol>
            </ol>

          </div>
        </Container>
      </Section>
  );
};

export default TermsContent;
