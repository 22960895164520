import React from 'react';
import './ComplexFeatures.scss';
import FirstFeature from '../../images/features/feature-one.png';
import SecondFeature from '../../images/features/feature-second.png';
import ThreeFeature from '../../images/features/feature-three.png';
import ComplexFeaturesItem from '../ComplexFeaturesItem';
import {uuidv4} from '../Utils';

const features = [
  {
    text: '"Акварели" - дом, в котором красиво всё. Надёжный и долговечный, с неповторимой гармонией двора и фасадов',
    imageSrc: FirstFeature
  },
  {
    text: 'Панорамное остекление от пола до потолка наполняет квартиру светом, преображая её и открывая приятный вид из окна',
    imageSrc: SecondFeature
  },
  {
    text: 'Парковочные места предусмотрены для различных типов автомобилей, а также оборудована лифтом на ваш этаж.',
    imageSrc: ThreeFeature
  },
];

const ComplexFeatures = ({className = '', ...props}) => {
  return (
      <div className={ `${ className } features` }>
        <ul className="features-list">
          {features.map(feature => (
              <li key={uuidv4()}
                  className="features-list__item">
                <ComplexFeaturesItem feature={feature}/>
              </li>
          ))}

        </ul>
      </div>
  );
};

export default ComplexFeatures;
