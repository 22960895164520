import React from 'react';
import {useLocation} from 'react-router-dom';
import './Header.scss';
import Container from '../Container';
import HeaderLogo from './HeaderLogo';
import HotCall from '../HotCall';
import HeaderLinks from '../HeaderLinks';
import ButtonWithDropdown from '../ButtonWithDropdown';

const Header = ({...props}) => {
  const location = useLocation();

  return (
      <div className={ `header ${ location.pathname === '/' ? 'header--absolute' : '' }` }>
        <Container className="header__container">
          <div className="header__inner">
            <div className="header__left-section">
              <HeaderLogo className="header__logo"/>
            </div>

            <div className="header__right-section">
              <HotCall className="header__callback"/>
              <HeaderLinks className="header__links"/>
            </div>

            <div className="header__mobile-section">
              <ButtonWithDropdown classNameDropdown="header__dropdown dropdown"
                                  dropdownComponent={ () => (
                                      <>
                                        <HotCall className="header__callback"/>
                                        <HeaderLinks className="header__links"/>
                                      </>
                                  ) }/>
            </div>
          </div>
        </Container>
      </div>
  );
};

export default Header;
