import React from 'react';
import './SectionPosition.scss';
import Section from '../Section';
import Container from '../Container';
import Description from '../Description';
import SecondaryTitle from '../SecondaryTitle';
import PositionList from '../PositionIList';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';
import InfoAboutMainCompany from '../InfoAboutMainCompany';
import GoogleMaps from '../GoogleMaps/GoogleMaps';

const SectionPosition = ({...props}) => {
  return (
      <Section className="section-position"
               white>
        <Container className="section-position__container">
          <div className="section-position__content">
            <Description className="section-position__description"
                         description="Собственная инфраструктура ЖК «Акварели» представлена сетью
                          коммерческих площадей, включая аптеку, медицинский центр,
                          салон красоты, кафе и многочисленные магазины, а также системой подземного
                           двухуровневого паркинга на 615 машиномест. Вместе с
                           гостевыми стоянками жилой комплекс вмещает 2800 машин.
                           На первых этажах расположена начальная школа и детский сад
                           «Филиппок», в которых трудятся высококвалифицированные педагоги и
                           воспитатели. В детском саду реализовано несколько дополнительных
                           образовательных программ и питание 5 раз в день."/>
            <Description className="section-position__description"
                         description="Придомовая территория ЖК «Акварели» занимает
                         свыше 7 га, а внутренний двор полностью огорожен. На каждом въезде
                         расположена будка охранников, которым в сохранении порядка помогают
                         многочисленные камеры видеонаблюдения, размещенные по периметру двора.
                         Попасть внутрь комплекса можно только по бесконтактным ключам-карточкам.
                          Внутри двора отсутствуют парковочные места для автомобилей, вместо
                          этого есть множество уютных детских и спортивных площадок."/>

            <SecondaryTitle className="section-position__title"
                            title="Расположение"
                            center/>


            <PositionList className="section-position__position-list"/>

            <GoogleMaps className="section-position__map"/>

            <Description className="section-position__description"
                         description="ЖК «Акварели» расположен в г. Балашиха Московской области, рядом с
                          крупной транспортной магистралью города – Шоссе Энтузиастов. На автомобиле въезд в
                           комплекс возможен не только со стороны Шоссе Энтузиастов (7 км от МКАД), но и со
                           стороны Щелковского или Носовихинского шоссе, расположенных в пределах 12 км от МКАД.
                           Неподалеку от «Акварели» находится железнодорожная станция «Балашиха», откуда можно
                           быстро добраться до Москвы."/>
            <Description className="section-position__description"
                         description="Из комплекса «Акварели» до Москвы легко доехать за
                           20 минут, именно столько времени займет путь на наземном общественном транспорте до
                           станции метро «Новогиреево». Путешествие до Курского вокзала потребует около 40 минут,
                            а всего в 100 метрах от комплекса находится остановка общественного транспорта «РГАЗУ».
                            В будущем в этом районе планируется провести полную реконструкцию Шоссе Энтузиастов и
                            построить трассу скоростного трамвая."/>

            <div className="section-position__choice">
              <CustomLink className="section-position__choice-button"
                          title="Выбрать квартиру"
                          button
                          url={FLATS_ROUTE}/>
            </div>

            <InfoAboutMainCompany className="section-position__info"/>

          </div>
        </Container>
      </Section>
  );
};

export default SectionPosition;
