import React from 'react';
import './Modal.scss';
import ReactModal from 'react-modal';
import {CloseIcon} from '../../icons';

ReactModal.setAppElement('#root');

const Modal = ({size, children = null, title = null, isOpen = false, onRequestClose = () => {}, ...props}) => {

  return (
      <ReactModal isOpen={isOpen}
                  {...props}
                  onRequestClose={onRequestClose}
                  className={`modal 
                  ${size ? `modal--${size}` : ''}`}
                  overlayClassName="overlay">
        <div className="modal__content">
          <CloseIcon className="modal__close"
                     onClick={onRequestClose}/>
          {/*<div className="modal__header">*/}
          {/*  {title}*/}
          {/*</div>*/}

          <div className="modal__body">
            {children}
          </div>
        </div>
      </ReactModal>
  )
};

export default Modal;
