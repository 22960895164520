import React from 'react';
import './Maintitle.scss';

const MainTitle = ({className = '', title, white = false, ...props}) => {
  return (
      <h1 className={`${className} title
                      ${white ? 'title--white' :''}`}>
        { title }
      </h1>
  );
};

export default MainTitle;
