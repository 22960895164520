import React from 'react';
import Modal from '../Modal';
import {useDispatch, useSelector} from 'react-redux';
import {closeCallbackModalModal} from '../../actions/modalActions';
import CallbackModalForm from '../CallBackModalForm';

const CallbackModal = ({...props}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.callback.isOpen)

  const handleClose = () => {
    dispatch(closeCallbackModalModal())
  }

  return (
      <Modal isOpen={isOpen}
             onRequestClose={handleClose}>
        <CallbackModalForm/>
      </Modal>
  );
};

export default CallbackModal;
