import React, {useEffect} from 'react';
import TermsContent from '../../components/TermsContent';
import {setPageTitle} from '../../components/Utils';

const TermPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Пользовательское соглашение');
  });
  return (
      <TermsContent/>
  );
};

export default TermPage;
