import React from 'react';
import './SectionFlats.scss';
import Section from '../Section';
import Container from '../Container';
import SecondaryTitle from '../SecondaryTitle';
import FlatsFeatures from '../FlatsFeatures';
import Description from '../Description';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';
import RandomFlats from '../RandomFlats/RandomFlats';

const SectionFlats = ({...props}) => {
  return (
      <Section className="section-flats"
               white>
        <Container className="section-flats__container">
          <div className="section-flats__content">
            <SecondaryTitle className="section-flats__title"
                            center
                            title="В наших квартирах:"/>
            <FlatsFeatures className="section-flats__features"/>

            <Description className="section-flats__description"
                         description="У нас вы можете снять квартиру в ЖК «Акварели»
                                    в долгосрочное пользование, с оплатой за каждый месяц.
                                    Помимо квартир мы предоставляем
                                    в аренду и парковочные места в удобном подземном паркинге. Мы сдаем в
                                    ренду и продаем только собственное жилье, без посредников.
                                    Не задумывайтесь, звоните уже сегодня, выбирайте в пользование
                                    одну из уютных квартир с современным качественным ремонтом в уникальном
                                    жилом комплексе «Акварели». Гарантируем, что просторные помещения с панорамными
                                    окнами во всю стену легко настроят на позитивный лад и станут приятным местом
                                    отдыха, которое ни за что не захочется покидать!"/>

            <SecondaryTitle className="section-flats__title"
                            center
                            title="Выберите квартиру"/>

            <RandomFlats className="section-flats__random-flats"/>

            <div className="section-flats__more">
              <CustomLink button
                          className="section-flats__more-link"
                          url={FLATS_ROUTE}
                          title="Показать еще"/>
            </div>
          </div>
        </Container>
      </Section>
  );
};

export default SectionFlats;
