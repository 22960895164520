import React from 'react';
import './Footer.scss';
import Container from '../Container';
import Copyright from '../Copyright';
import Contacts from '../Contacts';
import Menu from '../Menu';
import {
  ABOUT_COMPANY_ROUTE,
  ABOUT_COMPLEX_ROUTE,
  CONTACTS_ROUTE,
  FLATS_ROUTE,
  POLICY_ROUTE,
  REVIEWS_ROUTE,
  TERM_ROUTE
} from '../RouterConstasts';

const links = [
  {title: 'Квартиры', url: FLATS_ROUTE},
  {title: 'Контакты', url: CONTACTS_ROUTE},
  // {title: 'Вопросы-ответы', url: QUESTIONS_ROUTE},
  {title: 'Пользовательское соглашение', url: TERM_ROUTE},
]

const links_secondary = [
  {title: 'О компании', url: ABOUT_COMPANY_ROUTE},
  {title: 'О комплексе', url: ABOUT_COMPLEX_ROUTE},
  {title: 'Отзывы', url: REVIEWS_ROUTE},
  {title: 'Политика компании', url: POLICY_ROUTE},
]

const Footer = ({...props}) => {
  return (
    <div className="footer">
      <Container className="footer__container">
        <div className="footer__inner">
          <div className="footer__info">
            <Copyright className="footer__copyright"/>
            <Contacts className="footer__contacts"/>
          </div>

          <Menu className="footer__menu footer__menu--main"
                links={links}/>
          <Menu className="footer__menu footer__menu--secondary"
                links={links_secondary}/>
        </div>
      </Container>
    </div>
  )
};

export default Footer;
