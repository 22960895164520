import React from 'react';
import './ReviewsSlider.scss';
import Slider from '../Slider';
import SliderItem from '../SliderItem/SliderItem';
import SecondaryTitle from '../SecondaryTitle';
import {uuidv4} from '../Utils';
import clientAvatar from '../../images/people-icon.png';
import ThirdTitle from '../ThirdTitle';
import ReviewItem from '../ReviewItem';

const reviews = [
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Павел',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'В прошлом году приехали из Новосибирска покорять столицу. Обшарили все объявления «Аренда квартир». По нашим деньгам выбор был небольшим – однокомнатная развалюха в Москве или достойное жилье в Подмосковье. Изучив рынок, остановились на балашихинском ЖК «Акварель». Сейчас очень довольны своим выбором. Квартира выше всяких похвал. Инфраструктура – под окнами. Транспорт – не проблема. Обслуживание дома – на высоте.',
    limitations: 'Нету'
  },
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Кирил',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'Аренда квартир была не единственной моей задачей. Искал обязательно жилье с гаражом или паркингом. Когда приехал в гости к друзьям в Балашиху, понял, что ЖК Акварель то, что мне нужно. Великолепная квартира плюс прекрасный надежный паркинг для моего нового и горячо любимого авто. Живу и наслаждаюсь. Рекомендую!',
    limitations: 'Нету'
  },
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Андрей',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'Родители настаивали, чтобы жилье сняли обязательно около них. Иначе грозились не участвовать в расходах на оплату квартиры. Самым приличным оказался новый комплекс Акварель. Там и сняли двухкомнатное чудо. Никогда не думала, что аренда квартир может стать источником удовольствия. Но это именно то, что мы с мужем испытываем, находясь в нашей безумно светлой, теплой и красивой квартире. Родителям, естественно, респект.',
    limitations: 'Нету'
  },
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Юрий',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'Снять временное жилье заставили сложности в личной жизни – ушла от мужа. Друзья зазвали в свой комплекс Акварель, сказали, что там есть недорогая аренда квартир от застройщика. Послушалась. Сейчас живу здесь. В новой квартире успокоилась, привела мысли в порядок. Кажется, жизнь налаживается!',
    limitations: 'Нету'
  },
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Олексей',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'Аренда квартир была не единственной моей задачей. Искал обязательно жилье с гаражом или паркингом. Когда приехал в гости к друзьям в Балашиху, понял, что ЖК Акварель то, что мне нужно. Великолепная квартира плюс прекрасный надежный паркинг для моего нового и горячо любимого авто. Живу и наслаждаюсь. Рекомендую!',
    limitations: 'Нету'
  },
  {
    avatar: clientAvatar,
    created_at: '3 мая 2021',
    name: 'Максим',
    location: 'Зеленоград, Центральный проспект, 340',
    dignity: 'Когда главным вопросом моей жизни была срочная аренда квартир, один знакомый предложил посмотреть жилье в ЖК «Акварель» в Балашихе. Пошел из уважения к нему. Но когда увидел, что предлагают в аренду, настроение поменялось. Теперь живу на высоком этаже, наслаждаюсь картинами города и великолепного восхода солнца прямо ко мне в окна.',
    limitations: 'Нету'
  },
];

const ReviewsSlider = ({className = '', ...props}) => {
  return (
      <div className={`${className} reviews-slider-section`}>
        <SecondaryTitle title="Недавние отзывы"
                        className="reviews-slider-section__title"
                        center/>

        <ThirdTitle title="Мы проверяем достоверность каждого из них."
                    className="reviews-slider-section__subtitle"/>

        <Slider>
          { reviews.map(review => (
              <SliderItem key={ uuidv4() }>
                <ReviewItem review={review}/>
              </SliderItem>
          )) }
        </Slider>
      </div>

  );
};

export default ReviewsSlider;
