import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import axios from 'axios';
import {Provider} from 'react-redux';
import {DEVELOPMENT} from './components/Constants';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const composeEnhancers =
    (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    && process.env.REACT_APP_VERSION === DEVELOPMENT
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;


const initialState = {};

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={ store }>
      <GoogleReCaptchaProvider  reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                                language="ru"
                                useEnterprise={true}
                                scriptProps={{
                                  async: true,
                                  defer: true,
                                  appendTo: "head",
                                  nonce: undefined
                                }}>
      <App/>
      </GoogleReCaptchaProvider>
    </Provider>,
    document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
