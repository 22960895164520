import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import Checkbox from '../Checkbox';

const FilteredCheckbox = ({label, name, ...props}) => {
  const location = useLocation();
  const history = useHistory();

  let handleChange = (e) => {
    const query = queryString.parse(location.search);
    const {name, checked} = e.target;

    const nextQuery = {
      ...query,
      [name]: checked ? 1 : 0
    }

    if(nextQuery?.page) {
      nextQuery.page = undefined
    }

    if(!checked) {
      delete nextQuery[name]
    }

    history.push({
      pathName: window.location.pathname,
      search: queryString.stringify(nextQuery)
    })
  };

  let getParam = (key) => {
    const query = queryString.parse(location.search);

    return query[key] || ''
  };

  return (
      <Checkbox onChange={handleChange}
                {...props}
                checked={getParam(name) === '1'}
                value={getParam(name)}
                name={name}
                center
                id={name}
                label>
        {label}
      </Checkbox>
  );
};

export default FilteredCheckbox;
