import React from 'react';

import { ReactComponent as Location } from '../icons/contacts/location.svg';
import { ReactComponent as HotLine } from '../icons/contacts/hot-line.svg';

// import { ReactComponent as FeatureCoin } from '../icons/feature/feature-coin.svg';
// import { ReactComponent as FeatureComfort } from '../icons/feature/feature-comfort.svg';
// import { ReactComponent as FeatureLocation } from '../icons/feature/feature-location.svg';
// import { ReactComponent as FeatureParking } from '../icons/feature/feature-parking.svg';
// import { ReactComponent as FeatureSmile } from '../icons/feature/feature-smile.svg';
// import { ReactComponent as FeatureStatistic } from '../icons/feature/feature-statistic.svg';
// import { ReactComponent as FeatureTime } from '../icons/feature/feature-time.svg';

export const LocationIcon = (props) => {
  return <Location {...props}/>
}

export const HotLinePhoneIcon = (props) => {
  return <HotLine {...props}/>
}

export const ChevronsLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-chevrons-left ${className}`} />;
};

export const ChevronsRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-chevrons-right ${className}`} />;
};
export const MenuIcon = ({className = '', ...props}) => {
  return <i className={`icon-menu ${className}`} {...props} />;
};
export const RentabelnoLogoIcon = ({className = '', ...props}) => {
  return <i className={`icon-rentabelno-logo ${className}`} {...props} />;
};

export const ArrowUpIcon = ({className = '', ...props}) => {
  return <i className={`icon-up ${className}`} {...props} />;
};

export const ArrowDownIcon = ({className = '', ...props}) => {
  return <i className={`icon-down-arrow ${className}`} {...props} />;
};

export const FilterIcon = ({className = '', ...props}) => {
  return <i className={`icon-filter ${className}`} {...props} />;
}

export const FilterCloseIcon = ({className = '', ...props}) => {
  return <i className={`icon-filter-close ${className}`} {...props} />;
}

export const StatusLockIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-lock ${className}`} />;
};

export const StatusLockOpenIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-lock-open ${className}`} />;
};

export const PinIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-pin ${className}`} />;
};

export const ApplyIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-apply ${className}`} />;
};

export const ArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-arrow-left ${className}`} />;
};

export const ArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-arrow-right ${className}`} />;
};

export const BusIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-bus ${className}`} />;
};

export const CarIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-car ${className}`} />;
};

export const CloseIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-close ${className}`} />;
};

export const ComfortIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-comfort ${className}`} />;
};

export const GardenIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-garden ${className}`} />;
};

export const InteriorIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-interior ${className}`} />;
};

export const KinderGardenIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-kinder-garden ${className}`} />;
};

export const MarketsIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-markets ${className}`} />;
};

export const MetroIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-metro ${className}`} />;
};

export const CoinIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-coin ${className}`} />;
};

export const ParkingIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-parking ${className}`} />;
};

export const PhoneIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-phone ${className}`} />;
};

export const PlansIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-plans ${className}`} />;
};

export const PIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-p ${className}`} />;
};

export const HomeIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-home ${className}`} />;
};

export const SportIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-sport ${className}`} />;
};

export const SunIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-sun ${className}`} />;
};

export const TechIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-tech ${className}`} />;
};

export const TrainIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-train ${className}`} />;
};
