import React from 'react';
import './GoToMainSiteLink.scss';
import {ArrowRightIcon} from '../../icons';
import {MAIN_SITE_ROUTE} from '../RouterConstasts';

const GoToMainSiteLink = ({className = '', ...props}) => {
  return (
      <a href={MAIN_SITE_ROUTE}
         className={`${className} go-to-main`}
         rel="noreferrer"
         target="_blank">
        Перейти на сайт&nbsp;
        <ArrowRightIcon className="go-to-main__icon"/>
      </a>
  );
};

export default GoToMainSiteLink;
