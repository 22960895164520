import React from 'react';
import {NoCommissionIcon, RentabelnoIcon, ShieldIcon} from '../images';

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';


export const NOT_FOUND_DATA = 'К сожалению, по таким параметрам ничего не найдено';
export const ERROR_LOADING_DATA = 'К сожалению Ваш запрос не был обработан, попробуйте позже.';
export const SUCCESS_CALLBACK_MESSAGE = 'Спасибо, менеджер свяжеться с вами в ближайшее время!';
export const FORBIDDEN = 'FORBIDDEN';

export const OFFICE = 'Office';
export const PARKING = 'Parking';
export const APARTMENT = 'Apartment';


export const REALTY_RENT = 'Rent';
export const REALTY_SALE = 'Sale';

export const REALTY_AVAILABLE = 1;
export const REALTY_RESERVED = 2;
export const REALTY_OCCUPIED = 3;
export const REALTY_CLEANING_NEEDED = 4;
export const REALTY_PARTIAL_RENT = 5;


export const ADVANTAGE_POINTS = [
  {title: 'Мы не берем никаких комиссий за предоставление вещей в аренду', icon: () => <NoCommissionIcon/>},
  {title: 'Все объекты являются нашей собственностью', icon: () => <RentabelnoIcon/>},
  {title: 'Мы несем полную ответственность за состояние наших объектов', icon: () => <ShieldIcon/>}
];


export const RECAPTCHA_CONTACT_ACTION = 'contact';
export const RECAPTCHA_CALLBACK_ACTION = 'back_call';
