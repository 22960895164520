import {REALTY_AVAILABLE, REALTY_OCCUPIED} from './Constants';

export const setPageTitle = (title) => {
  document.title = title;
};

export const validateOnlyNumber = (value) => {
  const reg = /^[0-9]+$/;

  return reg.test(value);
};

export const valueFromId = (opts, id) => opts.find(o => o.value === id);
export const valueFromName = (opts, name) => opts.find(o => o.value === name);

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
};

export const getStringStatus = (status) => {
  switch ( status ) {
    case 1:
      return 'available';
    case 2:
      return 'reserved';
    case 3:
      return 'occupied';
    case 4:
      return 'cleaning-needed';
    case 5:
      return 'partial-rent';
    default:
      return null;
  }
};

export const getStringRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'long-term';
    case 2:
      return 'short-term';
    default:
      return null;
  }
};

export const getPriceTypeFromRent = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'мес.';
    case 2:
      return 'сутки';
    default:
      return null;
  }
};

export const getRusStringRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'Долгосрочная аренда';
    case 2:
      return 'Посуточная аренда';
    default:
      return null;
  }
};

export const getRusStringShortRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'Долгосрочно';
    case 2:
      return 'Посуточно';
    default:
      return null;
  }
};


export const getRealtyStatus = (status) => {
  switch ( status ) {
    case 1:
    case 2:
    case 4:
    case 6:
    case 7:
      return REALTY_AVAILABLE;
    case 3:
    case 5:
      return REALTY_OCCUPIED;
    default:
      return REALTY_AVAILABLE;
  }
};


export const getRoomsCase = (room_number) => {
  switch ( room_number ) {
    case -1:
      return `Квартира-студия`;
    case 1:
      return `${room_number} комната`;
    case 2:
      return `${room_number} комнаты`;
    case 3:
      return `${room_number} комнаты`;
    case 4:
      return `${room_number} комнаты`;
    case 5:
      return `${room_number} комнат`;
    default:
      return `${room_number} комната`;
  }
};
