import React from 'react';
import './Select.scss';
import ReactSelect from 'react-select';

const styles = {
  container: (provided, state) => ({
    position: 'relative',
    width: '100%',
    // marginRight: '10px',

    '&:last-child': {
      marginRight: '0',
    },
    ...(state.selectProps.customStyles.container && (
        state.selectProps.customStyles.container(provided, state)
    ))
  }),

  control: (provided, state) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    // height: '35px',
    background: '#FFFFFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: !state.menuIsOpen ? '#D2D2D2' : '#FCB842',
    borderRadius: !state.menuIsOpen ? '6px' : '6px 6px 0 0',
    // borderBottom: !state.menuIsOpen ? null : 'none',
    // borderBottomColor: !state.menuIsOpen ? '#D2D2D2' : 'transparent',
    boxShadow: !state.menuIsOpen ? null : '0 5px 10px 0 rgba(0,0,0,0.10)',
    overflow: 'hidden',
    cursor: 'pointer',
    zIndex: !state.menuIsOpen ? null : 9999,

    // padding: '10px 0 10px 15px',
    minHeight: 42,

    ...(state.selectProps.invalid && {
      borderWidth: 1,
      borderColor: !state.menuIsOpen ? '#FD5C6F' : '#D2D2D2',
    }),

    ...((state.selectProps.isMulti) && {
      padding: '0',
      minHeight: 42
    }),

    ...((state.selectProps.isMulti && state.hasValue) && {
      // padding: '10px 15px'
    }),

    ...(state.selectProps.customStyles.control && (
        state.selectProps.customStyles.control(provided, state)
    ))
  }),

  multiValue: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      // display: 'inline-flex',
      display: 'none',
      visibility: 'hidden',
      alignItems: 'center',
      padding: '7px 8px 7px 12px',
      background: '#FCB842',
      color: 'white',
      fontWeight: 300,
      borderRadius: '6px',
      fontSize: 12,
    }),

    ...((state.selectProps.isMulti && state.hasValue) && {
      marginRight: '6px'
    }),

    ...((state.selectProps.isMulti && state.hasValue && state.selectProps.value.length >= 2) && {
      marginBottom: '6px',
      marginRight: '6px'
    }),

  }),

  singleValue: (provided, state) => ({
    overflow: 'hidden',
    // position: 'absolute',
    // top: '50%',
    // transform: 'translateY(-50%)',
    color: '#272F4C',
    fontSize: 13,
    fontWeight: 400,
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',

    ...(state.selectProps.invalid && {
      color: '#FD5C6F',
    }),

    ...(state.selectProps.customStyles.singleValue && (
        state.selectProps.customStyles.singleValue(provided, state)
    ))
  }),

  input: (provided, state) => ({
    '*': {
      color: '#272F4C',
      fontSize: 13,
      fontWeight: 400,
    }
  }),

  valueContainer: (provided, state) => ({
    // padding: '10px 15px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px 0 15px',
    fontSize: 13,
    lineHeight: '15px',
    color: '#272F4C',

    ...(state.selectProps.invalid && {
      // padding: '11px 19px',
    }),

    ...(state.selectProps.customStyles.valueContainer && (
        state.selectProps.customStyles.valueContainer(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      // display: 'inline-flex',
      // flexWrap: 'wrap',
      // paddingRight: '24px',
      padding: '0 8px 0 15px',
      fontSize: 13,
      lineHeight: '15px',
      color: '#272F4C'
    }),
  }),

  placeholder: (provided, state) => ({
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '100%',
    fontSize: 13,
    lineHeight: '15px',
    color: '#272F4C',
    fontWeight: 400,
    padding: '10px 15px',

    ...(state.selectProps.invalid && {
      color: !state.selectProps.menuIsOpen ? '#FD5C6F' : '#272F4C',
    }),

    ...(state.selectProps.customStyles.placeholder && (
        state.selectProps.customStyles.placeholder(provided, state)
    ))
  }),

  indicatorsContainer: (provided, state) => ({
    // position: 'absolute',
    // width: '30px',
    // top: !state.selectProps.menuIsOpen ? 0 : -1,
    // right: !state.selectProps.menuIsOpen ? 0 : -1,
    // bottom: !state.selectProps.menuIsOpen ? 0 : -1,
    display: 'flex',
    alignItems: 'center',

    ...(state.selectProps.customStyles.indicatorsContainer && (
        state.selectProps.customStyles.indicatorsContainer(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      alignSelf: 'stretch'
      // padding: '10px 0'
    }),
  }),

  dropdownIndicator: (provided, state) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    height: '100%',
    color: '#43434C',
    transition: '.3s all',
    transform: !state.selectProps.menuIsOpen ? 'rotate(-90deg)' : 'rotate(0deg)',

    ...(state.selectProps.customStyles.dropdownIndicator && (
        state.selectProps.customStyles.dropdownIndicator(provided, state)
    )),

    ...(state.selectProps.isMulti && {
      // padding: !state.selectProps.menuIsOpen ? '0 20px 0 10px' : '0 20px'
      padding: '8px'
    }),
  }),

  noOptionsMessage: (provided, state) => ({
    padding: '10px 15px',
    color: '#43434C',
    fontSize: 13,
    lineHeight: '15px',
    cursor: 'default'
  }),

  menu: (provided, state) => ({
    position: 'absolute',
    display: 'block',
    top: 'calc(100% - 7px)',
    left: 0,
    right: 0,
    background: '#FFFFFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#FCB842',
    borderRadius: '0 0 6px 6px',
    borderTop: 'none',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.10)',
    overflow: 'hidden',
    // padding: '8px 0 8px 0',
    zIndex: 999999,

    ...(state.selectProps.isMulti && {
      // padding: !state.selectProps.menuIsOpen ? '0 20px 0 10px' : '0 20px'
      top: 'calc(100% - 2px)',
    }),

    ...(state.selectProps.customStyles.menu && (
        state.selectProps.customStyles.menu(provided, state)
    ))
  }),

  menuList: (provided, state) => ({
    maxHeight: '225px',
    overflowY: 'auto',
    scrollBehavior: 'smooth',

    '&::-webkit-scrollbar': {
      width: '10px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: '#FCB842'
    },
  }),

  option: (provided, state) => ({
    padding: state.isSelected ? '8px 15px' : '0 15px',
    margin: state.isSelected ? '4px 0' : '15px 0',
    display: 'block',
    color: '#272F4C',
    fontSize: 13,
    lineHeight: '15px',
    fontWeight: 400,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#FCB842' : 'inherit',

    '&:hover': {
      color: state.isSelected ? 'inherit' :'#FCB842',
    },

    ...(state.selectProps.customStyles.option && (
        state.selectProps.customStyles.option(provided, state)
    ))
  }),

  clearIndicator: (provided, state) => ({
    padding: '8px',
    height: '36px',
    // color: 'white',
    display: 'block',

    ...(state.selectProps.isMulti && {
      padding: '8px',
      height: '36px',
      // color: 'white',
      display: 'block'
    }),
  }),

  indicatorSeparator: (provided, state) => ({

    ...(state.hasValue && {
      alignSelf: 'stretch',
      backgroundColor: 'hsl(0,0%,80%)',
      marginBottom: '8px',
      marginTop: '8px',
      width: '1px',
      boxSizing: 'border-box',
    }),

    ...((state.selectProps.isMulti && state.hasValue) && {
      alignSelf: 'stretch',
      backgroundColor: 'hsl(0,0%,80%)',
      marginBottom: '8px',
      marginTop: '8px',
      width: '1px',
      boxSizing: 'border-box',
    }),
  }),

  multiValueLabel: (provided, state) => ({

    ...(state.selectProps.isMulti && {
      marginRight: '8px',
      maxWidth: `60px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
  }),

  multiValueRemove: (provided, state) => ({


    ...(state.selectProps.isMulti && {
      lineHeight: 0
    }),
  }),

};


const Select = ({customStyles = {}, components = {}, ...props}) => {
  return (
      <div className="select-control">
        <ReactSelect { ...props }
                     styles={ styles }
                     components={ components }
                     customStyles={ customStyles }
                     noOptionsMessage={ () => `Ничего не найдено` }
                     isSearchable={ false }
        />
      </div>
  );
};

export default Select;
