import React from 'react';
import './ObjectStatus.scss';
import {StatusLockIcon, StatusLockOpenIcon} from '../../icons';
import {getRealtyStatus, getStringStatus} from '../Utils';
import {REALTY_AVAILABLE, REALTY_OCCUPIED} from '../Constants';

const ObjectStatus = ({status, ...props}) => {
  return (
      <div className={`object-status 
                      ${status ? `object-status--${getStringStatus(status)}` : ''}
                      `}>
        {getRealtyStatus(status) === REALTY_AVAILABLE && <StatusLockOpenIcon/>}
        {getRealtyStatus(status) === REALTY_OCCUPIED && <StatusLockIcon/>}

      </div>
  );
};

export default ObjectStatus;
