import React from 'react';
import Complex from '../images/complex.png';

const ComplexImage = ({...props}) => {
  return (
      <div className="complex-image">
        <img src={ Complex } alt="complex Akvareli"/>
      </div>
  );
};

export default ComplexImage;
