import React, {useEffect} from 'react';
import {setPageTitle} from '../../components/Utils';
import PolicyContent from '../../components/PolicyContent';

const PolicyPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Политика компании');
  });
  return (
      <PolicyContent/>
  );
};

export default PolicyPage;
