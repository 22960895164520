import React from 'react';
import './OurContacts.scss';
import Phone from '../Phone';
import {HotLinePhoneIcon, LocationIcon} from '../../icons';

const OurContacts = ({className = '', ...props}) => {
  return (
      <div className={`our-contacts ${className}`}>
        <div className="our-contacts__row">
          <div className="our-contacts__row-inner">
            <div className="our-contacts__title-wrap">
              <div className="our-contacts__title-icon">
                <HotLinePhoneIcon/>
              </div>
              <div className="our-contacts__title">
                Горячая линия:
              </div>
            </div>
            <Phone className="our-contacts__phone"
                   color="orange"
                   formattedPhone="+7 (495) 221-52-59"
                   phone="74952215259"/>
            {/*&nbsp;/&nbsp;*/}
            {/*<Phone className="our-contacts__phone"*/}
            {/*       color="orange"*/}
            {/*       formattedPhone="+7 (967) 112-82-61"*/}
            {/*       phone="79671128261"/>*/}
          </div>
        </div>

        <div className="our-contacts__row">
          <div className="our-contacts__row-inner  our-contacts__row-inner--address-row">
            <div className="our-contacts__title-wrap our-contacts__title-wrap--address">
              <div className="our-contacts__title-icon">
                <LocationIcon/>
              </div>
              <div className="our-contacts__title">
                Адрес:
              </div>
            </div>
            <div className="our-contacts__address">
              Московская область, г. Балашиха, проспект Ленина 32А
            </div>
          </div>
        </div>

      </div>
  );
};

export default OurContacts;
