import React from 'react';
import './SectionOffer.scss';
import Section from '../Section';
import Container from '../Container';
import MainTitle from '../MainTitle';
import Description from '../Description';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';

const SectionOffer = ({...props}) => {
  return (
      <Section className="section-offer">
        <Container className="section-offer__container">
          <div className="section-offer__content">
            <div className="section-offer__inner">
              <MainTitle title="Аренда квартир в ЖК Акварели"
                         white
                         className="section-offer__title"/>
              <Description description="Мы сдаем и продаем удобные квартиры и
                                      паркинги в жилом комплексе “Акварели” в центральной
                                       части города Балашиха."
                           className="section-offer__description"
                           white/>
              <Description white
                           className="section-offer__description"
                           description="“Акварели” – это современный комплекс с удобной планировкой
                                          и богатой инфраструктурой."/>

              <CustomLink url={ FLATS_ROUTE }
                          button
                          className="section-offer__link"
                          title="Выбрать квартиру"/>
            </div>
          </div>
        </Container>
      </Section>
  );
};

export default SectionOffer;
