import React from 'react';
import './Input.scss';

const Input = ({icon: Icon = () => null, size, type = 'text', className = '',
                 error = null, multiline, name, rows, fullWidth,  ...props}) => {
  return (
      <div className={`input__root ${className}`}>
        {multiline ? (
            <textarea
                name={name}
                {...props}
                rows={rows}
                className={`input input--multiline 
                          ${error ? 'input--invalid' : ''}`}
            />
        ) : (
            <React.Fragment>
              <Icon className={`input__icon 
                                ${error ? 'input__icon--invalid' : ''}`}/>
              <input name={name}
                     type={type}
                     {...props}
                     className={`input ${error ? 'input--invalid' : ''}
                                 ${size ? `input--${size}` : ''}
                                 ${fullWidth ? `input--full-width` : ''}
                                 `}
                     autoComplete="off"
              />
            </React.Fragment>
        )}
        {error && (
            <div className="input__error">
              {error}
            </div>
        )}
      </div>
  );
};

export default Input;
