import React from 'react';
import './CallBackButton.scss';
import {PhoneIcon} from '../../icons';
import {useDispatch} from 'react-redux';
import {openCallbackModal} from '../../actions/modalActions';

const CallBackButton = ({...props}) => {
  const dispatch = useDispatch();

  let handleOpenModal = () => {
    dispatch(openCallbackModal())
  }

  return (
      <button onClick={handleOpenModal}
              className="callback-fixed">
        <PhoneIcon/>
      </button>
  );
};

export default CallBackButton;
