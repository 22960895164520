import React from 'react';
import './AboutComplexFlats.scss';
import Container from '../Container';
import SecondaryTitle from '../SecondaryTitle';
import RandomFlats from '../RandomFlats';
import CustomLink from '../CustomLink';
import {FLATS_ROUTE} from '../RouterConstasts';
import Section from '../Section';

const AboutComplexFlats = ({...props}) => {
  return (
      <Section silver
               className="about-complex-flats">
        <Container className="about-complex-flats__container">
          <div className="about-complex-flats__content">
            <SecondaryTitle center
                            className="about-complex-flats__subtitle"
                            title="Выберите квартиру"/>

            <RandomFlats className="about-complex-flats__random-flats"/>

            <div className="about-complex-flats__all-flats">
              <CustomLink url={ FLATS_ROUTE }
                          button
                          title="Все объекты"/>
            </div>
          </div>
        </Container>
      </Section>
  );
};

export default AboutComplexFlats;
