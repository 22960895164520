import React, {memo} from 'react';
import './ObjectItem.scss';
import ObjectStatus from '../ObjectStatus';
import {PARKING} from '../Constants';
import {getPriceTypeFromRent, getRoomsCase, getStringRentType, uuidv4} from '../Utils';
import ObjectPhoto from '../ObjectPhoto';
import {MAIN_SITE_ZHILIE_ROUTE} from '../RouterConstasts';


const ObjectItem = ({id, slug, parking_space, rooms, name, prices, room, type,
                      square, floor, status, image, ...props}) => {

  return (
      <a href={ `${MAIN_SITE_ZHILIE_ROUTE}/${ slug }` }
            rel="noreferrer"
            target="_blank"
            className="object">
        <ObjectStatus status={ status }/>
        <div className="object__inner">

          <ObjectPhoto image={ image }
                       className="object__image-wrap"
                       name={ name }/>
          <div className="object__properties">
            <div className="object__price-wrap">
              { prices && prices.map(item => (
                  <div key={ uuidv4() }
                       className={ `object__price object__price--${ getStringRentType(item.rent_type) }` }>
                    { item.price } руб./{ getPriceTypeFromRent(item.rent_type) }
                  </div>
              )) }
            </div>

            <div className="object__title">
              { name }
            </div>
            <div className="object__info">
              { (type !== PARKING && rooms)
                  ? <div className="object__info-part object__room">
                    { getRoomsCase(rooms) }
                  </div>
                  : null
              }
              { (type !== PARKING && square)
                  ? <div className="object__info-part object__square">
                    Площадь { square } м<sup>2</sup>
                  </div>
                  : null
              }

              { (type === PARKING && parking_space)
                  ? <div className="object__info-part ">
                    Парковочное место - { parking_space }
                  </div>
                  : null
              }

              { floor
                  ? <div className="object__info-part object__floor">
                    { floor } этаж
                  </div>
                  : null
              }

            </div>
          </div>
        </div>
      </a>
  );
};

export default memo(ObjectItem);
