import React from 'react';
import './ComplexFeaturesItem.scss';

const ComplexFeaturesItem = ({feature,  ...props}) => {
  return (
      <div className="feature">
        <div className='feature__image'>
          <img src={feature.imageSrc} alt=""/>
        </div>
        <div className="feature__divider">
          <span></span>
        </div>
        <div className="feature__description">
          {feature.text}
        </div>
      </div>
  );
};

export default ComplexFeaturesItem;
