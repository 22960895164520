import React, {memo} from 'react';
import './Objects.scss';
import ObjectItem from '../ObjectItem';
import {uuidv4} from '../Utils';

const Objects = ({objects, className = '', ...props}) => {

  return (
      <div className={ `${ className } objects` }>
        <ul className="objects__list">
          { objects && objects.map(obj => (
              <li key={ uuidv4() }
                  className="objects__list-item">
                <ObjectItem { ...obj }/>
              </li>
          )) }
        </ul>
      </div>
  );
};

export default memo(Objects);
