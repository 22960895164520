import React from 'react';
import './RandomFlats.scss';
import LazyLoad from 'react-lazyload';
import ObjectsContainer from '../Objects/ObjectsContainer';

const RandomFlats = ({ className = '', ...props}) => {
  return (
      <div className={`${className} random-flats`}>
        <LazyLoad height={ 1000 } once>
          <ObjectsContainer/>
        </LazyLoad>
      </div>

  );
};

export default RandomFlats;
