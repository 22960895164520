import React from 'react';
import './Description.scss';

const Description = ({className = '', description = '', center = false, white = false, action = '', ...props}) => {
  return (
      <div className={`description 
                      ${center ? 'description--center' :''}
                      ${white ? 'description--white' :''}
                      ${className}`}>
        {description}
        {action &&
          <>
            <br/>
            {action}
          </>
        }
      </div>
  );
};

export default Description;
