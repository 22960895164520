import React from 'react';
import './Advantages.scss';
import AdvantagesItem from '../AdvantagesItem';


const Advantages = ({className = '', title = '', horizontal = false, points = [], ...props}) => {
  return (
      <div className={`advantages ${className}`}>
        {title &&
          <div className="advantages__title">
            {title}
          </div>
        }

        {points &&
          <ul className={`advantages__list 
                          ${horizontal ? 'advantages__list--horizontal': ''}`}>
            {points.map((point, index) => <AdvantagesItem key={index}
                                                          className="advantages__list-item"
                                                          {...point}/>)}

          </ul>
        }

      </div>
  );
};

export default Advantages;
