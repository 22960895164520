import React from 'react';
import './Menu.scss';
import {Link, useLocation} from 'react-router-dom';
import {uuidv4} from '../Utils';

const Menu = ({className = '', label, disabled = false, links, ...props}) => {
  const location = useLocation();

  return (
      <div className={`menu ${className}`}>
        {/*<div className="menu__label">*/}
        {/*  {label}*/}
        {/*</div>*/}
        <div className="menu-list">
          {links.map(link => <Link key={uuidv4()}
                                    className={`menu-list__link 
                                                ${disabled ? 'menu-list__link--disabled' : ''}
                                                ${location.pathname === link.url ? 'menu-list__link--disabled' : ''}
                                                `}
                                    to={link.url}>
                                {link.title}
                              </Link>)}
        </div>
      </div>
  );
};

export default Menu;
