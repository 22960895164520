import React, {useEffect} from 'react';
import ReviewsContent from '../../components/ReviewsContent';
import {setPageTitle} from '../../components/Utils';

const ReviewsPage = ({...props}) => {
  useEffect(() => {
    setPageTitle('Отзывы');
  });
  return (
      <ReviewsContent/>
  );
};

export default ReviewsPage;
