import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import './FlatsFilter.scss';
import ComfortsSelectField from '../ComfortsSelectField';
import FloorSelectField from '../FloorSelectField';
import IsSellFilterCheckboxField from '../IsSellFilterCheckboxField';
import FromToFilterField from '../FromToFilterField';
import {uuidv4, validateOnlyNumber} from '../Utils';
import RoomsCountSelectField from '../RoomsCountSelectField';
import ClearFiltersButton from '../ClerFiltersButton';
import SortTypeSelectField from '../SortTypeSelectField';

const FlatsFilter = ({className = '', ...props}) => {
  const location = useLocation();
  const history = useHistory();

  let handleChange = (key, item) => {
    const query = queryString.parse(location.search);

    const nextQuery = {
      ...query,
      [key]: item?.value
    };

    if ( nextQuery?.page ) {
      nextQuery.page = undefined;
    }


    if ( !item?.value ) {
      delete nextQuery[key];
    }

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    });
  };

  let handleMultipleChange = (key, item) => {
    const query = queryString.parse(location.search);
    const values = item?.map(x => x.value);

    const nextQuery = {
      ...query,
      [key]: JSON.stringify(values)
    };

    if ( nextQuery?.page ) {
      nextQuery.page = undefined;
    }

    if ( !values || !values.length ) {
      delete nextQuery[key];
    }

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    });
  };

  let handleInputChange = (e) => {
    const {value, name} = e.target;
    const query = queryString.parse(location.search);

    const nextQuery = {
      ...query,
      [name]: value
    };

    if ( !validateOnlyNumber(value) ) {
      delete nextQuery[name];
    }

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    });

  };


  let getParams = (key) => {
    const query = queryString.parse(location.search);

    return query[key] || '';
  };


  return (
      <div className={`${className} flats-filter`}>
        <div className="flats-filter__top">
          <div className="flats-filter__top-part">
            <ComfortsSelectField currentValueIds={ getParams('conveniences') }
                                 onChange={ option => handleMultipleChange('conveniences', option) }/>
          </div>
          <div className="flats-filter__top-part">
            <FloorSelectField currentValueId={ getParams('floor') }
                              onChange={ option => handleChange('floor', option) }/>
          </div>
          {/*<div className="flats-filter__top-part">*/}
          {/*  <SubtypeRoomsSelectField currentValue={ getParams('subtype') }*/}
          {/*                           onChange={ option => handleChange('subtype', option) }/>*/}
          {/*</div>*/}

          <div className="flats-filter__top-part">
            <RoomsCountSelectField currentValueId={ getParams('rooms') }
                                   onChange={ option => handleChange('rooms', option) }/>
          </div>
          <div className="flats-filter__top-part">
            <IsSellFilterCheckboxField/>
          </div>
          {/*<div className="flats-filter__top-part">*/}
          {/*  <FreeFilterChekboxField/>*/}
          {/*</div>*/}
        </div>

        <div className="flats-filter__bottom">
          <div className="flats-filter__bottom-part">
            <SortTypeSelectField currentValue={ getParams('price_sort') }
                                   onChange={ option => handleChange('price_sort', option) }/>
          </div>
          <div className="flats-filter__bottom-part">
            <FromToFilterField label="Цена, руб:"
                               className="flats-filter__price"
                               nameFrom="price[from]"
                               nameTo="price[to]"
                               handleChange={ handleInputChange }
                               valueFrom={ getParams('price[from]') }
                               valueTo={ getParams('price[to]') }/>
          </div>
          <div className="flats-filter__bottom-part">
            <FromToFilterField label={ ['Площадь, м', <sup key={ uuidv4() }>2</sup>, ':'] }
                               className="flats-filter__area"
                               nameFrom="area[from]"
                               nameTo="area[to]"
                               handleChange={ handleInputChange }
                               valueFrom={ getParams('area[from]') }
                               valueTo={ getParams('area[to]') }/>
          </div>

          {/*{ hasSearchParams() &&*/}
          <div className="flats-filter__bottom-part">
            <ClearFiltersButton className="flats-filter__clear"/>
          </div>
          {/*}*/}

        </div>

      </div>
  );
};

export default FlatsFilter;
