import React from 'react';
import './AdvantagesItem.scss';

const AdvantagesItem = ({className = '', title, icon: Icon = null,  ...props}) => {

  return (
      <li className={className}
          {...props}>
        <div className="advantages-item">
          <div className="advantages-item__icon">
            <Icon/>
          </div>
          <div className="advantages-item__title">
            {title}
          </div>
        </div>
      </li>
  );
};

export default AdvantagesItem;
