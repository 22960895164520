import React from 'react';
import './ButtonDropdown.scss';
import {CloseIcon, MenuIcon} from '../../icons';

const ButtonDropdown = ({className = '', children, active, ...props}) => {
  return (
      <button className={ `${ className } button-dropdown` }
              { ...props }>
          <div className="button-dropdown__icon">
            {active ? <CloseIcon/> : <MenuIcon/>}
          </div>
        { children }
      </button>
  );
};

export default ButtonDropdown;
